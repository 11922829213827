import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {StringUtil} from "../../utils/strings";

export const TrimmedText: React.FC<{ text: string; maxLength: number }> = ({ text, maxLength }) => {
    const [showFullValue, setShowFullValue] = useState<boolean>(false);
    const {t} = useTranslation()
    if (text == null) return <></>;
    let originalValue: string = text;
    let valueNeedsToBeTrimmed = originalValue.length > maxLength;
    const getValue = () => {
        let value = originalValue;
        if (!showFullValue) value = StringUtil.trim(originalValue, maxLength);
        return value;
    };
    let toggleShowFullValue = () => {
        if (valueNeedsToBeTrimmed) {
            setShowFullValue(!showFullValue);
        }
    };
    return (
        <span
            onClick={toggleShowFullValue}
            style={valueNeedsToBeTrimmed ? { cursor: "pointer" } : {}}
            title={valueNeedsToBeTrimmed ? t("publications.showAll") : ""}
        >
      {getValue()}
    </span>
    );
};