import React from 'react'
import styles from './index.module.scss';

export interface HeaderButtonsProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}
export const HeaderButtons: React.FC<HeaderButtonsProps> = ({
    children,
    ...props
}) => {
    return (<div className={styles["header-buttons"]} {...props}>
        { children }
    </div>);
}