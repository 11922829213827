import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';


export interface TenderListProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const TenderList: React.FC<TenderListProps> = ({
    children,
    ...props
}) => {
    const {t, i18n} = useTranslation();
    return (<div className={styles['container']}>
        <div
            className={styles['tender-list-title']}
        > 
            {t("companyPage.recently_participated_in_section_title")} 
        </div>
        <div 
            className={styles['tender-list']}
            {...props}
        >
            { children }
        </div>
    </div>);
}