export interface Feature {
    name: FeatureName;
    price?: number;
    limit?: number;
}

export enum FeatureName {

    OPPORTUNITIES_TAB="OPPORTUNITIES_TAB",
    LEADS_TAB="LEADS_TAB",
    AWARDS_TAB="AWARDS_TAB",
    SEARCH_TAB="SEARCH_TAB",
    FRAMEWORKS_TAB="FRAMEWORKS_TAB",

    /***
     *  BUTTONS
     */
    LABELS="LABELS",
    EXPORT_EXCEL="EXPORT_EXCEL",
    BESIX_FORCE="BESIX_FORCE",
    SCREENING="SCREENING",
    AI_FILE_ANALYSIS="AI_FILE_ANALYSIS",

    /***
     *  SEARCH FUNCTIONALITY
     */
    SEARCH_ADDENDA="SEARCH_ADDENDA",
    SEARCH_RANGE_DAYS="SEARCH_RANGE_DAYS",

    /***
     *  COUNTRIES
     */
    COUNTRIES="COUNTRIES",

    /***
     *  UPDATE MAIL
     */
    //FREQUENCY
    UPDATE_MAIL_DAILY="UPDATE_MAIL_DAILY",
    UPDATE_MAIL_WEEKLY="UPDATE_MAIL_WEEKLY",
        //LIMITS
    UPDATE_MAIL_OPPORTUNITIES="UPDATE_MAIL_OPPORTUNITIES",
    UPDATE_MAIL_LEADS="UPDATE_MAIL_LEADS",
    UPDATE_MAIL_AWARDS="UPDATE_MAIL_AWARDS",

    /***
     *  USER PROFILES
     */
        //AMOUNT
    CUSTOM_USER_PROFILES="CUSTOM_USER_PROFILES",
    CURATED_USER_PROFILES="CURATED_USER_PROFILES",
    //FUNCTIONALITY
    USER_PROFILES_SEARCH_ADDENDA="USER_PROFILES_SEARCH_ADDENDA",

    /***
     *  SUPPORT
     */
    SUPPORT_BY_MAIL="SUPPORT_BY_MAIL",
    SUPPORT_BY_PHONE="SUPPORT_BY_PHONE",
    /**
     * PUBLISHERS
     */
    PUBLISHERS_AT = "PUBLISHERS_AT",
    PUBLISHERS_BE = "PUBLISHERS_BE",
    PUBLISHERS_BG = "PUBLISHERS_BG",
    PUBLISHERS_CH = "PUBLISHERS_CH",
    PUBLISHERS_CY = "PUBLISHERS_CY",
    PUBLISHERS_CZ = "PUBLISHERS_CZ",
    PUBLISHERS_DE = "PUBLISHERS_DE",
    PUBLISHERS_DK = "PUBLISHERS_DK",
    PUBLISHERS_EE = "PUBLISHERS_EE",
    PUBLISHERS_ES = "PUBLISHERS_ES",
    PUBLISHERS_FI = "PUBLISHERS_FI",
    PUBLISHERS_FR = "PUBLISHERS_FR",
    PUBLISHERS_GR = "PUBLISHERS_GR",
    PUBLISHERS_HU = "PUBLISHERS_HU",
    PUBLISHERS_IE = "PUBLISHERS_IE",
    PUBLISHERS_IS = "PUBLISHERS_IS",
    PUBLISHERS_IT = "PUBLISHERS_IT",
    PUBLISHERS_HR = "PUBLISHERS_HR",
    PUBLISHERS_LV = "PUBLISHERS_LV",
    PUBLISHERS_LT = "PUBLISHERS_LT",
    PUBLISHERS_LU = "PUBLISHERS_LU",
    PUBLISHERS_MT = "PUBLISHERS_MT",
    PUBLISHERS_NL = "PUBLISHERS_NL",
    PUBLISHERS_NO = "PUBLISHERS_NO",
    PUBLISHERS_PL = "PUBLISHERS_PL",
    PUBLISHERS_PT = "PUBLISHERS_PT",
    PUBLISHERS_RO = "PUBLISHERS_RO",
    PUBLISHERS_SE = "PUBLISHERS_SE",
    PUBLISHERS_SI = "PUBLISHERS_SI",
    PUBLISHERS_SK = "PUBLISHERS_SK",
    PUBLISHERS_UK = "PUBLISHERS_UK",
}

export namespace FeatureParser {
    export function toString(feature: FeatureName): string {
        return FeatureName[feature];
    }

    export function fromString(feature: string): FeatureName {
        return (FeatureName as any)[feature.toUpperCase()];
    }
}