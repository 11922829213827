import { ResourceKey } from "i18next";

export const Time: ResourceKey = {
	from: "from",
	to: "to",
	today: "today",
	thisWeek: "this week",
	thisMonth: "this month",
	notExpired: "not expired",
	year: "year",
	month: "month",
	day: "day",
	years: "years",
	months: "months",
	days: "days",
};
