import {S3FileDocument, Tender} from "../types/tender";
import {TenderUtil} from "./tenders";
import {MultilingualFieldUtil} from "./multilingualfield";

export namespace S3FileUtil {

    export function extractFileName(file: S3FileDocument) {
        if (file && file.s3Key) {
            const lastSlashIndex = file.s3Key.lastIndexOf('/');
            if (lastSlashIndex !== -1) {
                return file.s3Key.substring(lastSlashIndex + 1);
            }
        } else {
            return file.fileName;
        }
    }

    export function getDownloadableFiles(tender: Tender): S3FileDocument[]{
        const files: S3FileDocument[] = tender.addenda ? [...tender.addenda] : [];
        const uniqueKeys = new Set();
        const undownloadableFileTypes = ['CONTENT_FILE_HUMAN_READABLE', 'CONTENT_FILE_MACHINE_READABLE', 'META_FILE'];
        TenderUtil.getPublicationInformationsSorted(tender).forEach(pubInfo => {
            if (pubInfo.publicationInformation.files) {
                pubInfo.publicationInformation.files.forEach(file => {
                    if (file.s3Key != null && !undownloadableFileTypes.includes(file.type) && !uniqueKeys.has(file.s3Key)) {
                        files.push(file);
                        uniqueKeys.add(file.s3Key);
                    //     distinctFileNames.push(file.fileName);
                    }});
            }});
        return files;
    }

    export function filterSpecifications(files: S3FileDocument[]) {
        return files.filter(file =>
            file.type === 'ADMINISTRATIVE_SPECIFICATIONS'
            || file.type === 'TECHNICAL_SPECIFICATIONS'
            || file.type === 'SELECTION_GUIDE'
        );
    }

    export function createArchiveFileName(tender: Tender, languageIso: string) {
        let fileName: string = MultilingualFieldUtil.translate(tender.title, languageIso).toLowerCase();
        fileName = fileName.replace(/[éêèë]/g, "e");
        fileName = fileName.replace(/[áâàä]/g, "e");
        fileName = fileName.replace(/[óôòö]/g, "e");
        fileName = fileName.replace(/[úûùü]/g, "e");
        fileName = fileName.replace(/[íîìï]/g, "e");
        fileName = fileName.replace(/[^a-z0-9\s]/g, "");
        let parts: string[] = fileName.split(" ").filter(part => part.length > 0);
        if (parts.length > 12) parts = parts.slice(0, 12);
        return parts.join("_") + ".zip";
    }
}