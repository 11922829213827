import { ResourceKey } from "i18next";

export const DetailsSideBar: ResourceKey = {
	share: "Deel",
	shareThisTender: "Deel deze tender",
	alsoShareWithMe: "Zend me ook deze e-mail",
	isGroupMail: "Als groepsmail verzenden",
	tenderHasBeenSharedWith: "De tender is gedeeld met",
	tenderHasAlreadyBeenSharedWith: "Reeds gedeeld met",
	viaEmail: "Via e-mail",
	emailAddress: "E-mailadres(sen)",
	addAMessage: "Voeg een bericht toe",
	removeLead: "Verwijder van shortlist",
	makeLead: "Zet op shortlist",
	makeLeadTitle: "Zet op shortlist: hou me op de hoogte wanneer er nieuwe publicaties zijn en geef een overzicht in het shortlist-tabblad",
	follow: "Volgen",
	unfollow: "Ontvolgen",
	undeleteOpportunity: "Voeg opportuniteit terug toe",
	deleteOpportunity: "Verwijder opportuniteit",
	label: "Label",
	languages: "Talen",
	language: "Taal",
	downloadFullProject: "Download volledig project",
	match: "Verklaring match",
	addToBesixForce: "Voeg toe aan BesixForce",
	goToBesixForce: "BesixForce project",
	showSimilarTenders: "Gelijkaardige opdrachten",
	screeningButton: "Screening",
	screeningTitle: "Toon bedrijven die reeds meegedongen hebben voor deze aanbesteder",
};
