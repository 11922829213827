import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {

	company_name: "Organization",
	company_information_section_title: "Company information",
	main_competitors_section_title: "Main competitors",
	recent_activity_section_title: "Recent public tenders",
	recently_won_section_title: "Recently won",
	recently_participated_in_section_title: "Recently participated in",
	main_buyers_section_title: "Main buyers",
	tenders_contracted_open_title: "Open tenders",
	tenders_contracted_closed_title: "Recently issued tenders",
	main_suppliers_section_title: "Main suppliers",
	main_activities_section_title: "Types of public tenders",
	accreditations_title: "Accreditations",
	bid: "offer",
	award: "award",
	total_results: "Total number of results: ",
	disclaimer: "The company page is a beta feature, and a first step toward full-blown market analysis. Submit feedback via info@tenderwolf.com",
	unknown_company: "Unknown company",
	follow_company: "Follow this company: keep me informed when this company participates in a public tender and provide an overview in the awards tab",
	show_company_page: "Show company page",

};