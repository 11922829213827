import React, { useState, useEffect } from "react";
import { Dropdown } from "../../../dropdown";
import { LanguageItem } from "../languageItem";
import i18next from "i18next";

export interface LanguageDropdownProps {
    languages ?: string[];
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ 
    languages = ['en', 'fr', 'nl'] ,
}) => {
    const [currentLanguage, setCurrentLanguage] = useState(
        getCurrentLanguageIso(languages)
    );

    const handleLanguageChange = (
        language: string,
    ) => {
        i18next.changeLanguage(language);
        setCurrentLanguage(language);
    };

    return (
        <Dropdown initialSelectedValue={currentLanguage}>
            {languages.map((language, index) => (
                <Dropdown.Option 
                    key={index} 
                    value={language}
                    onClick={() => handleLanguageChange(language)}
                >
                    <LanguageItem language={language} />                
                </Dropdown.Option>
            ))}
        </Dropdown>
    );
};

function getCurrentLanguageIso(allLanguages: string[]): string {
    let browserLanguage = i18next.language.substring(0, 2);
    return allLanguages.includes(browserLanguage) ? browserLanguage : "en";
}