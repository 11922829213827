
import React from 'react'
import styles from './index.module.scss';

export  interface CallToActionButtonProps 
        extends React.HTMLAttributes<HTMLButtonElement> {
    onClick?: () => void;
    text?: string;
}

export const CallToActionButton: React.FC<CallToActionButtonProps> = ({
    text = "",
    className = null,
    onClick = () => {},
    ...props
}) => {
    return (
            <button 
                className={className ? 
                    className : 
                    styles["call-to-action-button"]
                }
                onClick={onClick}
                {...props}
            >
                { text }
            </button>
    );
}