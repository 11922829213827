import { ResourceKey } from "i18next";

export const Navigation: ResourceKey = {
	page: "page",
	previous: "Previous",
	next: "Next",
	showMore: "Show more",
	showLess: "Show less",
	pleaseLogIn: "Please log in to view this page",
	logIn: "Log in",
	opportunities: "Opportunities",
	leads: "Shortlist",
	awards: "Awards",
	frameworks: "Expiring Contracts",
	search: "Search",
	account: "Account",
	whatIsTenderwolf: "What is Tenderwolf?",
	followYourCompetitors: "Track your competitors",
	showTenderInformation: "Back to opportunity",
};
