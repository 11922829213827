import { ResourceKey } from "i18next";

export const Time: ResourceKey = {
	from: "van",
	to: "tot",
	today: "vandaag",
	thisWeek: "deze week",
	thisMonth: "deze maand",
	notExpired: "nog niet verstreken",
	year: "jaar",
	month: "maand",
	day: "dag",
	years: "jaren",
	months: "maanden",
	days: "dagen",
};
