import { ResourceKey } from "i18next";

export const Navigation: ResourceKey = {
	page: "pagina",
	previous: "Vorige",
	next: "Volgende",
	showMore: "Bekijk meer",
	showLess: "Bekijk minder",
	pleaseLogIn: "Log in om deze pagina te bekijken",
	logIn: "Inloggen",
	opportunities: "Opportuniteiten",
	leads: "Shortlist",
	awards: "Gunningen",
	frameworks: "Aflopende Contracten",
	search: "Zoeken",
	account: "Account",
	whatIsTenderwolf: "Wat is Tenderwolf?",
	followYourCompetitors: "Volg je concurrenten",
	showTenderInformation: "Terug naar opportuniteit",
};
