import React from "react";
import "./loader.scss";
import TenderWolfLogo from '../../assets/logo/black-logo-icon.svg';
import {SparklesIcon} from "../icons";
import {motion} from "framer-motion";

export const Loader: React.FC = () => {
    return (
        <div>
            <div className="loader-wrapper">
                <div className={'loader-box'}>
                    <div className="loader-element spinner"></div>
                    <div className={'loader-element'}>
                        <div style={{padding: '22px', filter: 'invert(1)'}}>
                            <img src={TenderWolfLogo} alt="TenderWolfLogo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const AILoader: React.FC = () => {
    return (
        <div>
            <div className="loader-wrapper">
                <div className={'loader-box'}>
                    {/*<div className="loader-element spinner"></div>*/}
                    <motion.div className={'loader-element'}
                                animate={{ rotate: [0, 360] }}
                                transition={{ duration: 1.6, repeat: Infinity }}>
                        <div style={{padding: '22px', filter: 'invert(1)'}}>
                            <SparklesIcon/>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}