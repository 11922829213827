import React from "react";
import { motion } from "framer-motion";
import { FADE_IN } from "../../../../consts/FramerMotionVariants";
import { useTranslation } from "react-i18next";
import { DotsWhiteIcon } from "../../../../components/icons";
import { useNavigate } from "react-router-dom";

interface SavedSearchFilterButtonsProps {
  allProfileNames: string[];
  savedSearchIsActive: (name: string) => boolean;
  addSavedSearch: (name: string) => boolean;
  removeSavedSearch: (name: string) => boolean;
}

export const SavedSearchFilterButtons: React.FC<SavedSearchFilterButtonsProps> = ({ allProfileNames, savedSearchIsActive, addSavedSearch, removeSavedSearch}) => {
    return <motion.div
    className="search-filter-button-alignment"
    variants={{ hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.2 } } }}
    initial="hidden"
    animate="visible"
  >
    {allProfileNames.map((search, i) => (
      <div key={i}>
        <SavedSearchFilterButton
          name={search}
          isActive={savedSearchIsActive(search)}
          add={addSavedSearch}
          remove={removeSavedSearch}
        />
      </div>
    ))}
  </motion.div>;
};

interface SavedSearchFilterButtonProps {
    name: string;
    isActive: boolean;
    remove: (name: string) => boolean;
    add: (name: string) => boolean;
}

export const SavedSearchFilterButton: React.FC<SavedSearchFilterButtonProps> = ({ name, isActive, add, remove }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const toggle = () => {
    if (isActive) {
      remove(name);
    } else {
      add(name);
    }
  };
  if (name === "") return <></>;
  return (
    <>
      {/*{!showNameInput && (*/}
      <motion.button
        variants={FADE_IN}
        style={
          isActive
            ? {
                borderColor: "var(--black-5)",
                backgroundColor: "var(--black-5)",
                color: "var(--bg-white)",
              }
            : {
                borderColor: "var(--bg-light-grey)",
                backgroundColor: "var(--bg-light-grey)",
                color: "var(--bg-white)",
              }
        }
        whileHover={{ opacity: 0.5, transition: { duration: 0.2 } }}
        animate={{ opacity: 1 }}
      >
        <span
          onClick={toggle}
          style={{
            cursor: "pointer",
            paddingRight: 0,
            color: "var(--bg-white)",
          }}
        >
          {t("savedSearchSettings.SavedSearchFilterButtonTitle") + ": " + name}
        </span>
        <span
          title={t("savedSearchSettings.settings")}
          onClick={() => navigate("/account?profile=" + name)}
          style={{
            width: "6px",
            marginLeft: "6px",
            cursor: "pointer",
          }}
        >
          <DotsWhiteIcon />
        </span>
      </motion.button>
    </>
  );
};
