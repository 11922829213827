import React, { HTMLAttributes } from 'react';


export interface OptionProps extends HTMLAttributes<HTMLDivElement> {
    value: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Option: React.FC<OptionProps> = ({ 
    value, 
    onClick, 
    children,
    ...props
}) => (
    <div 
        onClick={onClick}
        {...props}
    >
        {children}
    </div>
);