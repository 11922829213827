import { Country, CountryParser } from "../consts/countries";
import { Feature, FeatureName } from "../consts/features";
import { SearchFilter, SearchFilterParser } from "../consts/SearchFilter";
import { FeatureUtil } from "./features";
import { SearchFilterState } from "../hooks/slices/tenderSearchSlice";
import { DateUtil } from "./date";
import moment, { Moment } from "moment";
import { SearchType } from "../consts/searchType";
import { FieldGroup } from "../consts/FieldGroup";

export namespace SearchFilterUtil {
  export function contains(filters: SearchFilter[] | undefined, filter: SearchFilter) {
    if (filters) return filters.includes(filter);
    else return false;
  }

  export function findPublicationDateFilter(filters: SearchFilter[] | undefined): SearchFilter | undefined {
    for (const f of filters ? filters : []) {
      if (isPublicationDateFilter(f)) return f;
    }
  }

  export function isPublicationDateFilter(filter: SearchFilter): boolean {
    return filter.startsWith("PUBLICATION_DATE");
  }

  export function changePublicationDateFilter(selectedFilters: SearchFilter[], newDateFilter: SearchFilter) {
    selectedFilters = selectedFilters.filter((f) => !SearchFilterUtil.isPublicationDateFilter(f));
    return [...selectedFilters, newDateFilter];
  }

  export function isDeadlineFilter(filter: SearchFilter): boolean {
    return filter.startsWith("DEADLINE");
  }

  export function isEstimatedRenewalDate(filter: SearchFilter): boolean {
    return filter.startsWith("ESTIMATED_RENEWAL_DATE");
  }

  export function changeDeadlineDateFilter(selectedFilters: SearchFilter[], newDateFilter: SearchFilter) {
    selectedFilters = selectedFilters.filter((f) => !SearchFilterUtil.isDeadlineFilter(f));
    return [...selectedFilters, newDateFilter];
  }

  export function isAwardOrOpeningReportPublicationDateFilter(searchFilter: SearchFilter): boolean {
    return searchFilter.startsWith("AWARD_OR_OPENING_REPORT_PUBLICATION_DATE");
  }

  export function getDeadlineFilters(): SearchFilter[] {
    return Object.values(SearchFilter).filter((f) => isDeadlineFilter(f));
  }

  export function getAwardOrOpeningReportPublicationDateFilters(): SearchFilter[] {
    return Object.values(SearchFilter).filter((f) => isAwardOrOpeningReportPublicationDateFilter(f));
  }

  export function findPublicationDeadlineFilters(searchFilters: SearchFilter[]): SearchFilter[] {
    return searchFilters.filter((f) => isDeadlineFilter(f));
  }

  export function findAwardOrOpeningReportPublicationDateFilter(
    filters: SearchFilter[] | undefined
  ): SearchFilter | undefined {
    for (const f of filters ? filters : []) {
      if (isAwardOrOpeningReportPublicationDateFilter(f)) return f;
    }
  }

  export function findDeadlineDateFilter(filters: SearchFilter[] | undefined): SearchFilter | undefined {
    for (const f of filters ? filters : []) {
      if (isDeadlineFilter(f)) return f;
    }
  }

  export function findEstimatedRenewalDateFilter(filters: SearchFilter[] | undefined): SearchFilter | undefined {
    for (const f of filters ? filters : []) {
      if (isEstimatedRenewalDate(f)) return f;
    }
  }

  export function isDateRangeFilter(f: SearchFilter): boolean {
    return isPublicationDateFilter(f) || isDeadlineFilter(f) || isAwardOrOpeningReportPublicationDateFilter(f);
  }

  export function isDateRangeEverythingFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_EVERYTHING");
  }

  export function isDateRangeCustomFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_CUSTOM");
  }

  export function isDateRangeYesterdayFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_YESTERDAY");
  }

  export function isDateRangeLastWeekFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_LAST_WEEK");
  }

  export function isDateRangeLastPreviousWeekOnlyFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_PREVIOUS_WEEK_ONLY");
  }

  export function isDateRangeLastMonthFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_LAST_MONTH");
  }

  export function isDateRangeLastTwoMonthsFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_LAST_TWO_MONTHS");
  }

  export function isDateRangeLastYearFilter(f: SearchFilter): boolean {
    return isDateRangeFilter(f) && f.endsWith("_LAST_YEAR");
  }

  export function isCountryFilter(filter: SearchFilter) {
    return filter.startsWith("COUNTRY_");
  }

  export function getCountriesFromCountryFilters(searchFilters: SearchFilter[]): Country[] {
    return searchFilters
      .filter((f) => isCountryFilter(f))
      .map((f) => f.replace("COUNTRY_", ""))
      .map((f) => CountryParser.fromString(f));
  }

  export function getCountryFilters(): SearchFilter[] {
    return SearchFilterParser.findAll().filter((f) => SearchFilterUtil.isCountryFilter(f));
  }

  export function getCountryFiltersFromAvailableCountries(availableCountries: Country[]): SearchFilter[] {
    let filters: SearchFilter[] = [];
    for (const f of getCountryFilters()) {
      for (const c of availableCountries) {
        if (f.replace("COUNTRY_", "") === c) filters.push(f);
      }
    }
    return filters;
  }

  export function getCountryFiltersFromFeatures(features: Feature[] | undefined) {
    if (features) {
      let availableCountries = FeatureUtil.getCountriesFromPublisherFeatures(features);
      return getCountryFiltersFromAvailableCountries(availableCountries);
    } else return [];
  }

  export function addOrRemoveSearchFilter(
    element: SearchFilter,
    currentlySelectedFilters: SearchFilter[]
  ): SearchFilter[] {
    if (currentlySelectedFilters.map((e) => e).includes(element))
      return currentlySelectedFilters.filter((e) => e !== element);
    else return [...currentlySelectedFilters, element];
  }

  export function changeDropdownFilter(
    newFilter: SearchFilter,
    dropdownFilters: SearchFilter[],
    currentlySelectedFilters: SearchFilter[]
  ): SearchFilter[] {
    let currentlySelectedFiltersWithoutDropdownValues = currentlySelectedFilters.filter(
      (f) => !dropdownFilters.includes(f)
    );
    return [...currentlySelectedFiltersWithoutDropdownValues, newFilter];
  }

  export function getPublicationTypeFiltersForSavedSearch(): SearchFilter[] {
    return [
      SearchFilter.HAS_PRIOR_INFORMATION_NOTICE,
      SearchFilter.HAS_CONTRACT_NOTICE,
      SearchFilter.HAS_DESIGN_CONTEST,
      SearchFilter.HAS_MUNICIPAL_DECISION,
    ];
  }

  export function getPublicationTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.HAS_PRIOR_INFORMATION_NOTICE,
      SearchFilter.HAS_CONTRACT_NOTICE,
      SearchFilter.HAS_CONTRACT_AWARD_NOTICE,
      SearchFilter.HAS_DESIGN_CONTEST,
      SearchFilter.HAS_AWARD_MODIFICATIONS,
      SearchFilter.HAS_CORRIGENDA,
      SearchFilter.HAS_OPENING_REPORT,
      SearchFilter.HAS_MUNICIPAL_DECISION,
    ];
  }

  export function getAddendaFilters(): SearchFilter[] {
    return [SearchFilter.HAS_ADDENDA, SearchFilter.DOES_NOT_HAVE_ADDENDA];
  }

  export function translateContainsFieldsFilters(searchFilters: SearchFilterState): string[] {
    return translateContainsFieldsFilterStrings(searchFilters.selectedFilters);
  }

  export function translateContainsFieldsFilterStrings(selectedFilters: string[]): string[] {
    let publicationTypes: string[] = [];
    for (const f of Array.from(selectedFilters)) {
      if (f === SearchFilter.HAS_PRIOR_INFORMATION_NOTICE) publicationTypes.push("priorInformationNoticeLots");
      else if (f === SearchFilter.HAS_CONTRACT_NOTICE) publicationTypes.push("contractNoticeLots");
      else if (f === SearchFilter.HAS_CONTRACT_AWARD_NOTICE) publicationTypes.push("contractAwards");
      else if (f === SearchFilter.HAS_DESIGN_CONTEST) publicationTypes.push("designContests");
      else if (f === SearchFilter.HAS_AWARD_MODIFICATIONS) publicationTypes.push("awardModifications");
      else if (f === SearchFilter.HAS_CORRIGENDA) publicationTypes.push("corrigendumChanges");
      else if (f === SearchFilter.HAS_OPENING_REPORT) publicationTypes.push("openingReportBids");
      else if (f === SearchFilter.HAS_MUNICIPAL_DECISION) publicationTypes.push("municipalDecisions");
      else if (f === SearchFilter.HAS_ADDENDA) publicationTypes.push("addenda");
      else if (f === SearchFilter.HAS_AWARDS) publicationTypes.push("awardCompanyNames");
    }
    return publicationTypes;
  }

  export function translateContainsFieldsFiltersBack(fieldNames: string[] | undefined): SearchFilter[] {
    if (fieldNames == null) return [];
    let filters: SearchFilter[] = [];
    for (const f of fieldNames) {
      if (f === "priorInformationNoticeLots") filters.push(SearchFilter.HAS_PRIOR_INFORMATION_NOTICE);
      if (f === "contractNoticeLots") filters.push(SearchFilter.HAS_CONTRACT_NOTICE);
      if (f === "contractAwards") filters.push(SearchFilter.HAS_CONTRACT_AWARD_NOTICE);
      if (f === "designContests") filters.push(SearchFilter.HAS_DESIGN_CONTEST);
      if (f === "awardModifications") filters.push(SearchFilter.HAS_AWARD_MODIFICATIONS);
      if (f === "corrigendumChanges") filters.push(SearchFilter.HAS_CORRIGENDA);
      if (f === "openingReportBids") filters.push(SearchFilter.HAS_OPENING_REPORT);
      if (f === "municipalDecisions") filters.push(SearchFilter.HAS_MUNICIPAL_DECISION);
      if (f === "addenda") filters.push(SearchFilter.HAS_ADDENDA);
      if (f === "awardCompanyNames") filters.push(SearchFilter.HAS_AWARDS);
    }
    return filters;
  }

  export function translateDoesNotContainFieldsFilters(searchFilters: SearchFilterState): string[] {
    let publicationTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE)
        publicationTypes.push("priorInformationNoticeLots");
      if (f === SearchFilter.DOES_NOT_HAVE_CONTRACT_NOTICE) publicationTypes.push("contractNoticeLots");
      if (f === SearchFilter.DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE) publicationTypes.push("contractAwards");
      if (f === SearchFilter.DOES_NOT_HAVE_DESIGN_CONTEST) publicationTypes.push("designContests");
      if (f === SearchFilter.DOES_NOT_HAVE_AWARD_MODIFICATIONS) publicationTypes.push("awardModifications");
      if (f === SearchFilter.DOES_NOT_HAVE_CORRIGENDA) publicationTypes.push("corrigendumChanges");
      if (f === SearchFilter.DOES_NOT_HAVE_OPENING_REPORT) publicationTypes.push("openingReportBids");
      if (f === SearchFilter.DOES_NOT_HAVE_ADDENDA) publicationTypes.push("addenda");
    }
    return publicationTypes;
  }

  export function getProcedureTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.PROCEDURE_TYPE_OPEN,
      SearchFilter.PROCEDURE_TYPE_RESTRICTED,
      SearchFilter.PROCEDURE_TYPE_NEGOTIATED,
      SearchFilter.PROCEDURE_TYPE_COMPETITIVE_DIALOGUE,
      SearchFilter.PROCEDURE_TYPE_INNOVATION_PARTNERSHIP,
      SearchFilter.PROCEDURE_TYPE_OTHER,
    ];
  }

  export function translateProcedureTypeFilters(searchFilters: SearchFilterState): string[] {
    let procedureTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f.startsWith("PROCEDURE_TYPE_")) procedureTypes.push(f);
    }
    return procedureTypes;
  }

  export function getFieldGroupFilters(): SearchFilter[] {
    return [
      // SearchFilter.FIELD_GROUP_TITLE,
      // SearchFilter.FIELD_GROUP_DESCRIPTION,
      // SearchFilter.FIELD_GROUP_SPECIFICATIONS,
      SearchFilter.FIELD_GROUP_ADDENDA,
    ];
  }

  export function translateFieldGroupFilters(searchFilters: SearchFilterState): FieldGroup[] {
    let fieldGroups: FieldGroup[] = [];
    let defaultFieldGroups: FieldGroup[] = [
      FieldGroup.TITLE,
      FieldGroup.DESCRIPTION,
      FieldGroup.CONTRACTING_AUTHORITY,
      FieldGroup.FULLCONTENT,
    ];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.FIELD_GROUP_TITLE) fieldGroups.push(FieldGroup.TITLE);
      if (f === SearchFilter.FIELD_GROUP_DESCRIPTION) fieldGroups.push(FieldGroup.DESCRIPTION);
      if (f === SearchFilter.FIELD_GROUP_SPECIFICATIONS) fieldGroups.push(FieldGroup.SPECIFICATIONS);
      if (f === SearchFilter.FIELD_GROUP_AWARD_COMPANY_NAMES) fieldGroups.push(FieldGroup.AWARD_COMPANY_NAMES);
      if (f === SearchFilter.FIELD_GROUP_BID_COMPANY_NAMES) fieldGroups.push(FieldGroup.BID_COMPANY_NAMES);
      if (f === SearchFilter.FIELD_GROUP_CONTRACTING_AUTHORITY) fieldGroups.push(FieldGroup.CONTRACTING_AUTHORITY);
      if (f === SearchFilter.FIELD_GROUP_ADDENDA) {
        fieldGroups.push(FieldGroup.ADDENDA);
        defaultFieldGroups.forEach((f) => fieldGroups.push(f));
      }
    }
    if (fieldGroups.length === 0) defaultFieldGroups.forEach((f) => fieldGroups.push(f));
    return fieldGroups;
  }

  export function getPublicationRadiusFilters(): SearchFilter[] {
    return [SearchFilter.PUBLICATION_RADIUS_EUROPEAN_UNION, SearchFilter.PUBLICATION_RADIUS_NATIONAL];
  }

  export function translatePublicationRadiusFilters(searchFilters: SearchFilterState): string | undefined {
    let publicationRadiuses: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.PUBLICATION_RADIUS_EUROPEAN_UNION) publicationRadiuses.push("EUROPEAN_UNION");
      if (f === SearchFilter.PUBLICATION_RADIUS_NATIONAL) publicationRadiuses.push("NATIONAL");
    }
    if (publicationRadiuses.length === 1) return publicationRadiuses[0];
    else return undefined;
  }

  export function getContractTypeFilters(): SearchFilter[] {
    return [SearchFilter.CONTRACT_TYPE_SERVICES, SearchFilter.CONTRACT_TYPE_WORKS, SearchFilter.CONTRACT_TYPE_SUPPLIES];
  }

  export function translateContractTypeFilters(searchFilters: SearchFilterState): string[] {
    let contractTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.CONTRACT_TYPE_SERVICES) contractTypes.push("SERVICES");
      if (f === SearchFilter.CONTRACT_TYPE_WORKS) contractTypes.push("WORKS");
      if (f === SearchFilter.CONTRACT_TYPE_SUPPLIES) contractTypes.push("SUPPLIES");
    }
    return contractTypes;
  }

  export function getContractingAuthorityTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_CGA,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_RA,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_LA,
      // SearchFilter.CONTRACTING_AUTHORITY_TYPE_DEF_CONT,//temporarily added to 'other' because there are no instances
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_UTILITIES,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_OTHER,
    ];
  }

  export function translateContractingAuthorityTypeFilters(searchFilters: SearchFilterState): string[] {
    let contractingAuthorityTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f.startsWith("CONTRACTING_AUTHORITY_TYPE_")) contractingAuthorityTypes.push(f);
    }
    return contractingAuthorityTypes;
  }

  export function findDeadlineStartDate(selectedFilters: SearchFilter[]): string | undefined {
    for (const f of Array.from(selectedFilters)) {
      if (f === SearchFilter.DEADLINE_EVERYTHING) return ""; //this effectively resets the date parameter
      if (f === SearchFilter.DEADLINE_NOT_YET_EXPIRED) return DateUtil.getDateTimeString(DateUtil.today());
      if (f === SearchFilter.DEADLINE_IN_7_DAYS) return DateUtil.getDateTimeString(DateUtil.today());
      if (f === SearchFilter.DEADLINE_IN_14_DAYS) return DateUtil.getDateTimeString(DateUtil.today());
      if (f === SearchFilter.DEADLINE_IN_1_MONTH) return DateUtil.getDateTimeString(DateUtil.today());
      if (f === SearchFilter.DEADLINE_IN_2_MONTHS) return DateUtil.getDateTimeString(DateUtil.today());
      if (f === SearchFilter.DEADLINE_THIS_YEAR)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year(), 0, 1)));
      if (f === SearchFilter.DEADLINE_PREVIOUS_YEAR)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year() - 1, 0, 1)));
      if (f === SearchFilter.DEADLINE_TWO_YEARS_AGO)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year() - 2, 0, 1)));
      if (f === SearchFilter.DEADLINE_MORE_THAN_TWO_YEARS_AGO) return undefined;
    }
    return undefined;
  }

  export function findDeadlineEndDate(selectedFilters: SearchFilter[]): string | undefined {
    for (const f of Array.from(selectedFilters)) {
      if (f === SearchFilter.DEADLINE_EVERYTHING) return ""; //this effectively resets the date parameter
      if (f === SearchFilter.DEADLINE_NOT_YET_EXPIRED) return ""; //this effectively resets the date parameter
      if (f === SearchFilter.DEADLINE_IN_7_DAYS) return DateUtil.getDateTimeString(DateUtil.todayPlusDays(7));
      if (f === SearchFilter.DEADLINE_IN_14_DAYS) return DateUtil.getDateTimeString(DateUtil.todayPlusDays(14));
      if (f === SearchFilter.DEADLINE_IN_1_MONTH) return DateUtil.getDateTimeString(DateUtil.todayPlusMonths(1));
      if (f === SearchFilter.DEADLINE_IN_2_MONTHS) return DateUtil.getDateTimeString(DateUtil.todayPlusMonths(2));
      if (f === SearchFilter.DEADLINE_THIS_YEAR)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year() + 1, 0, 1)));
      if (f === SearchFilter.DEADLINE_PREVIOUS_YEAR)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year(), 0, 1)));
      if (f === SearchFilter.DEADLINE_TWO_YEARS_AGO)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year() - 1, 0, 1)));
      if (f === SearchFilter.DEADLINE_MORE_THAN_TWO_YEARS_AGO)
        return DateUtil.getDateTimeString(moment(new Date(DateUtil.today().year() - 2, 0, 1)));
    }
    return undefined;
  }

  export function findPublicationStartDate(
    selectedFilters: SearchFilter[],
    earliestStartDate: Moment | undefined
  ): string | undefined {
    for (const f of Array.from(selectedFilters)) {
      if (isPublicationDateFilter(f) || isAwardOrOpeningReportPublicationDateFilter(f)) {
        if (isDateRangeEverythingFilter(f)) {
          return "";
        } //this effectively resets the date parameter
        if (isDateRangeYesterdayFilter(f)) {
          return DateUtil.getDateString(DateUtil.todayMinusDays(1));
        }
        if (isDateRangeLastWeekFilter(f)) {
          return DateUtil.getDateString(DateUtil.todayMinusDays(7));
        }
        if (isDateRangeLastMonthFilter(f)) {
          return DateUtil.getDateString(DateUtil.todayMinusMonths(1));
        }
        if (isDateRangeLastTwoMonthsFilter(f)) {
          return DateUtil.getDateString(DateUtil.todayMinusMonths(2));
        }
        if (isDateRangeLastYearFilter(f)) {
          return DateUtil.getDateString(DateUtil.todayMinusYears(1));
        }
        if (isDateRangeLastPreviousWeekOnlyFilter(f)) {
          return DateUtil.getDateString(DateUtil.getStartOfPreviousWeek());
        }
        if (isDateRangeCustomFilter(f)) {
          return earliestStartDate ? DateUtil.getDateString(earliestStartDate) : undefined;
        }
      }
    }
    return undefined;
  }

  export function findPublicationEndDate(selectedFilters: SearchFilter[]): string | undefined {
    for (const f of Array.from(selectedFilters)) {
      if (isPublicationDateFilter(f) || isAwardOrOpeningReportPublicationDateFilter(f)) {
        if (isDateRangeEverythingFilter(f)) return ""; //this effectively resets the date parameter
        if (isDateRangeYesterdayFilter(f)) return DateUtil.getDateString(DateUtil.today());
        if (isDateRangeLastWeekFilter(f)) return DateUtil.getDateString(DateUtil.today());
        if (isDateRangeLastMonthFilter(f)) return DateUtil.getDateString(DateUtil.today());
        if (isDateRangeLastTwoMonthsFilter(f)) return DateUtil.getDateString(DateUtil.today());
        if (isDateRangeLastYearFilter(f)) return DateUtil.getDateString(DateUtil.today());
        if (isDateRangeLastPreviousWeekOnlyFilter(f)) return DateUtil.getDateString(DateUtil.getEndOfPreviousWeek());
      }
    }
    return undefined;
  }

  export function getEarliestStartDateForUser(features: Feature[] | undefined): Moment | undefined {
    let searchRangeLookBackInDays: number | undefined = FeatureUtil.getFeatureLimit(
      features,
      FeatureName.SEARCH_RANGE_DAYS
    );
    if (searchRangeLookBackInDays) {
      //weirdly !== undefined doesn't work here
      if (searchRangeLookBackInDays === 365) return DateUtil.todayMinusYears(1);
      else if (searchRangeLookBackInDays === 31) return DateUtil.todayMinusMonths(2);
      else return DateUtil.todayMinusDays(searchRangeLookBackInDays);
    } else return undefined;
  }

  export function getRelevantDeadlineFilters(): SearchFilter[] {
    return [
      SearchFilter.DEADLINE_EVERYTHING,
      SearchFilter.DEADLINE_NOT_YET_EXPIRED,
      SearchFilter.DEADLINE_IN_7_DAYS,
      SearchFilter.DEADLINE_IN_14_DAYS,
      SearchFilter.DEADLINE_IN_1_MONTH,
      SearchFilter.DEADLINE_IN_2_MONTHS,
      SearchFilter.DEADLINE_CUSTOM,
    ];
  }

  export function getPublicationDateFilters(): SearchFilter[] {
    return Object.values(SearchFilter).filter((f) => isPublicationDateFilter(f));
  }

  export function findPublicationDateFilters(searchFilters: SearchFilter[]): SearchFilter[] {
    return searchFilters.filter((f) => isPublicationDateFilter(f));
  }

  export function getRelevantPublicationDateFilters(
    earliestStartDate: Moment | undefined,
    searchType: SearchType
  ): SearchFilter[] {
    let filters: SearchFilter[] = [];
    let filtersUnlimited = earliestStartDate === undefined || searchType === SearchType.SHORTLIST;
    if (filtersUnlimited) filters.push(SearchFilter.PUBLICATION_DATE_EVERYTHING);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusDays(1)))
      filters.push(SearchFilter.PUBLICATION_DATE_YESTERDAY);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusDays(7)))
      filters.push(SearchFilter.PUBLICATION_DATE_LAST_WEEK);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusMonths(1)))
      filters.push(SearchFilter.PUBLICATION_DATE_LAST_MONTH);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusMonths(2)))
      filters.push(SearchFilter.PUBLICATION_DATE_LAST_TWO_MONTHS);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusYears(1)))
      filters.push(SearchFilter.PUBLICATION_DATE_LAST_YEAR);
    if (filtersUnlimited) filters.push(SearchFilter.PUBLICATION_DATE_CUSTOM);
    return filters;
  }

  export function getRelevantAwardOrOpeningReportPublicationDateFilters(
    earliestStartDate: Moment | undefined,
    searchType: SearchType
  ): SearchFilter[] {
    let filters: SearchFilter[] = [];
    let filtersUnlimited = earliestStartDate === undefined || searchType === SearchType.SHORTLIST;
    if (filtersUnlimited) filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusDays(1)))
      filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusDays(7)))
      filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusMonths(1)))
      filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH);
    if (filtersUnlimited || earliestStartDate?.isSameOrBefore(DateUtil.todayMinusYears(1)))
      filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR);
    if (filtersUnlimited) filters.push(SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM);
    return filters;
  }

  export function getRelevantEstimatedRenewalDateFilters() {
    return [
      SearchFilter.ESTIMATED_RENEWAL_DATE_NEXT_MONTH,
      SearchFilter.ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS,
      SearchFilter.ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS,
      SearchFilter.ESTIMATED_RENEWAL_DATE_NEXT_YEAR,
      SearchFilter.ESTIMATED_RENEWAL_DATE_ALL,
      SearchFilter.ESTIMATED_RENEWAL_DATE_CUSTOM
    ];
  }

  // This function takes the first filter with the broadest range and puts it at the beginning of the array,
  // then sorts the rest of the filters by ascending range, and finally adds the custom filter at the end.
  export function sortPublicationDateFilters(
    filters: SearchFilter[],
    selectedFilter: SearchFilter | undefined
  ): SearchFilter[] {
    if (filters.length === 0) return filters;
    let sortedFilters: SearchFilter[] = [];
    let firstFilter = selectedFilter;
    if (firstFilter == null) firstFilter = getPublicationDateSearchFilterWithBroadestRange(filters);
    sortedFilters.push(firstFilter);
    let otherFiltersSortedByRange = filters
      .filter((f) => f !== firstFilter)
      .filter((f) => isDateRangeFilter(f))
      .filter((f) => !isDateRangeCustomFilter(f))
      .sort((a, b) => {
        let aDate = getDateRangeFilterPseudoDayRange(a);
        let bDate = getDateRangeFilterPseudoDayRange(b);
        if (aDate === undefined || bDate === undefined) return 0;
        return aDate - bDate;
      });
    sortedFilters.push(...otherFiltersSortedByRange);
    let customFilter = filters.find((f) => isDateRangeCustomFilter(f));
    if (customFilter !== undefined) sortedFilters.push(customFilter); //should always be at the end
    return sortedFilters;
  }

  function getPublicationDateSearchFilterWithBroadestRange(publicationDateSearchFilters: SearchFilter[]): SearchFilter {
    let broadestFilter = publicationDateSearchFilters[0];
    for (const f of publicationDateSearchFilters) {
      if (getDateRangeFilterPseudoDayRange(f) > getDateRangeFilterPseudoDayRange(broadestFilter)) {
        broadestFilter = f;
      }
    }
    return broadestFilter;
  }

  function getDateRangeFilterPseudoDayRange(f: SearchFilter): number {
    if (isDateRangeYesterdayFilter(f)) return 1;
    if (isDateRangeLastWeekFilter(f)) return 7;
    if (isDateRangeLastMonthFilter(f)) return 31;
    if (isDateRangeLastTwoMonthsFilter(f)) return 62;
    if (isDateRangeLastYearFilter(f)) return 365;
    if (isDateRangeEverythingFilter(f)) return 1000;
    return 0;
  }

  export function getEstimatedRenewalStartDate(filters: SearchFilter[]): string | undefined {
    for (const f of filters) {
      if (f === "ESTIMATED_RENEWAL_DATE_NEXT_MONTH") {return DateUtil.getDateString(DateUtil.todayPlusDays(31))}
      if (f === "ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS") {return DateUtil.getDateString(DateUtil.todayPlusDays(91))}
      if (f === "ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS") {return DateUtil.getDateString(DateUtil.todayPlusDays(181))}
      if (f === "ESTIMATED_RENEWAL_DATE_NEXT_YEAR") {return DateUtil.getDateString(DateUtil.todayPlusDays(366))}
    } return undefined;
  }

  export function getEstimatedRenewalEndDate(filters: SearchFilter[]): string | undefined {
    return undefined;
  }

}
