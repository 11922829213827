import React from "react";
import styles from "./index.module.scss";

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
    children: React.ReactNode;
    id?: string;
}


export const Title: React.FC<TitleProps> = ({
    children,
    id="related-tenders-title",
    ...props
}) => {return (<header
        className={styles.header}
    >
        <h1
            id={id}
            {...props}
        >
            {children}
        </h1>
    </header>)};