import React from "react";
import {
    CompanyIcon1,
    CompanyIcon2,
    CompanyIcon21, CompanyIcon23,
    CompanyIcon3, CompanyIcon5,
    GovernmentIcon,
    HospitalIcon,
    SchoolIcon,
} from "./index";

export const NameBasedCompanyIcon: React.FC<{ companyName: string }> = ({ companyName }) => {
  let name = companyName.toLowerCase();
  if (name.includes("stad") || name.includes("gemeente")) return <GovernmentIcon />;
  if (name.includes("onderwijs") || name.includes("school")) return <SchoolIcon />;
  if (name.includes("zorg")) return <HospitalIcon />;
  if (name.endsWith("nv") || name.endsWith("sa")) return <CompanyIcon2 />;
  if (name.endsWith("bv") || name.endsWith("srl")) return <CompanyIcon3 />;
  if (name.match("[a-z][.][a-z][.][a-z][.]$")) return <CompanyIcon21 />;
  else return <CompanyIcon23/>;
};