export namespace StringUtil{

    export function extractMiddle(str: string, window: number): string {
        let center = Math.ceil(str.length / 2);
        let offset = Math.ceil(window / 2);
        return str.substr(center - offset, window);
    }

    export function trim(str: string, maxLength: number): string {
        if (str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        } else return str;
    }

}