import {
  SearchFilterState,
  SearchFilterUpdateProps,
  SimilarTenderFilter,
  TenderSearchState
} from "../hooks/slices/tenderSearchSlice";
import { SearchType } from "../consts/searchType";
import { SearchFilter, SearchFilterParser } from "../consts/SearchFilter";
import {CodeUtil, CpvUtil} from "./codes";
import { SubjectCodes } from "../consts/subjectCodes";
import {CodeBranch, CodeType, CodeWithClass} from "../types/code";
import { RegionCodes } from "../consts/regionCodes";
import { Accreditations } from "../consts/accreditations";
import { DateUtil } from "./date";
import {SortBy, SortByParser} from "../consts/SortBy";
import {UserInteraction} from "../hooks/slices/userSlice";

export namespace SearchUrlUtil {


  export function construct(searchState: TenderSearchState): string {
    let params: string[] = [];
    if (!searchState.searchInput || !searchState.searchType) return getRoot(SearchType.OPPORTUNITIES);
    let input = searchState.searchInput.mainInput;
    let searchType = searchState.searchType;
    if (searchType === SearchType.AWARDS) {
      if (searchState.searchFilters.searchShortlist) params.push("awards_tab=shortlist");
      else if (searchState.searchFilters.savedSearches !== undefined) params.push("awards_tab=sector");
      else params.push("awards_tab=all");
    }
    if (input.query) params.push("query=" + input.query);
    // if (input.page) params.push("page=" + input.page);
    // if (input.pageSize) params.push("page_size=" + input.pageSize);
    // if (input.sortBy) params.push("sort_by=" + input.sortBy);
    if (input.languageIsos && input.languageIsos.length > 0) params.push("languages=" + input.languageIsos.join(","));
    if (input.countries && input.countries.length > 0) params.push("countries=" + input.countries.join(","));
    if (input.subjectCodes && input.subjectCodes.length > 0)
      params.push("subject_codes=" + input.subjectCodes.map((c) => CpvUtil.getCpvFullCode(c.code)).join(","));
    if (input.regionCodes && input.regionCodes.length > 0)
      params.push("region_codes=" + input.regionCodes.map((c) => c.code).join(","));
    if (input.accreditations && input.accreditations.length > 0)
      params.push("accreditations=" + input.accreditations.map((c) => c.code + "-" + c.budgetClass).join(","));
    if (input.publicationStartDate) params.push("start_publication_date=" + input.publicationStartDate);
    if (input.publicationEndDate) params.push("end_publication_date=" + input.publicationEndDate);
    if (input.deadlineStartDate) params.push("start_deadline_date=" + input.deadlineStartDate);
    if (input.deadlineEndDate) params.push("end_deadline_date=" + input.deadlineEndDate);
    return getRoot(searchType) + "?" + params.join("&");
  }

  function getRoot(searchType: SearchType) {
    switch (searchType) {
      case SearchType.OPPORTUNITIES:
        return "/opportunities";
      case SearchType.SHORTLIST:
        return "/shortlist";
      case SearchType.AWARDS:
        return "/awards";
      case SearchType.SEARCH:
        return "/search";
      case SearchType.FRAMEWORKS:
        return "/frameworks";
      default:
        return "/search";
    }
  }

  //todo only add where the filter is present in the sidebar?
  export function getUrlSearchParamsFilters(
    currentFilters: SearchFilterState,
    searchParams: URLSearchParams,
    userInteractions: UserInteraction[]
  ): SearchFilterUpdateProps {
    return {
      query: searchParams.get("query") ?? currentFilters.query,
      page: validateNumber(searchParams.get("page")) ?? currentFilters.page,
      pageSize: validateNumber(searchParams.get("pageSize")) ?? currentFilters.pageSize,
      sortBy: validateSortBy(searchParams.get("sortBy")) ?? currentFilters.sortBy,
      // awardSavedSearches: searchParams.get("awards_tab") === 'sector',
      searchShortlist: searchParams.get("awards_tab") === 'all',
      subjectCodes: getSubjectCodeFilters(searchParams) ?? currentFilters.subjectCodes,
      regionCodes: getRegionCodeFilters(searchParams) ?? currentFilters.regionCodes,
      accreditations: getAccreditationFilters(searchParams) ?? currentFilters.accreditations,

      startPublicationDate: validateDate(searchParams.get("start_publication_date")) ?? currentFilters.startPublicationDate,
      endPublicationDate: validateDate(searchParams.get("end_publication_date")) ?? currentFilters.endPublicationDate,
      startDeadlineDate: validateDateTime(searchParams.get("start_deadline_date")) ?? currentFilters.startDeadlineDate,
      endDeadlineDate: validateDateTime(searchParams.get("end_deadline_date")) ?? currentFilters.endDeadlineDate,

      similarTender: getSimilarTender(searchParams) ?? currentFilters.similarTender,

      selectedFilters: getSelectedFilters(currentFilters.selectedFilters, searchParams),

      userInteractions: userInteractions
    };
  }

  function getSimilarTender(searchParams: URLSearchParams): SimilarTenderFilter | undefined {
    let uuid = searchParams.get("similar_tender_uuid");
    let tag = searchParams.get("similar_tender_tag");
    if (uuid && tag) return {uuid: uuid, tag: tag};
    return undefined;
  }

  function getSelectedFilters(currentFilters: SearchFilter[], searchParams: URLSearchParams): SearchFilter[] {
    let selectedFilters: SearchFilter[] = [...currentFilters];
    let languages = searchParams.get("languages");
    if (languages) {
      addLanguageFilters(selectedFilters, languages.split(","));
    }
    let countries = searchParams.get("countries");
    if (countries) {
      addCountryFilters(selectedFilters, countries.split(","));
    }
    if (validateDate(searchParams.get("start_publication_date"))) {
      addCustomPublicationDateFilter(selectedFilters, SearchFilter.PUBLICATION_DATE_CUSTOM);
    }
    if (validateDate(searchParams.get("end_publication_date"))) {
      addCustomPublicationDateFilter(selectedFilters, SearchFilter.PUBLICATION_DATE_CUSTOM);
    }
    if (validateDate(searchParams.get("start_deadline_date"))) {
      addCustomDeadlineDateFilter(selectedFilters, SearchFilter.DEADLINE_CUSTOM);
    }
    if (validateDate(searchParams.get("end_deadline_date"))) {
      addCustomDeadlineDateFilter(selectedFilters, SearchFilter.DEADLINE_CUSTOM);
    }
    let formTypes = searchParams.get("form_types");
    if(formTypes) {
      addFormTypeFilters(selectedFilters, formTypes.split(","));
    }
    return selectedFilters;
  }

  function addCustomPublicationDateFilter(
    filters: SearchFilter[],
    publicationDateFilter: SearchFilter
  ) {
    filters = filters.filter(
      (f) => !f.startsWith("PUBLICATION_DATE")
    );
    filters.push(publicationDateFilter);
  }

  function addCustomDeadlineDateFilter(
      filters: SearchFilter[],
    publicationDateFilter: SearchFilter
  ) {
    filters = filters.filter(
      (f) => !f.startsWith("DEADLINE_")
    );
    filters.push(publicationDateFilter);
  }

  function addLanguageFilters(filters: SearchFilter[], languageIsos: string[]) {
    for (const iso of languageIsos) {
      let languageFilter: SearchFilter = SearchFilterParser.fromString("LANGUAGE_" + iso.toUpperCase().trim());
      if (languageFilter && !filters.includes(languageFilter)) {
        filters.push(languageFilter);
      }
    }
  }

  function addCountryFilters(filters: SearchFilter[], countryIsos: string[]) {
    for (const iso of countryIsos) {
      let countryFilter: SearchFilter = SearchFilterParser.fromString("COUNTRY_" + iso.toUpperCase().trim());
      if (countryFilter && !filters.includes(countryFilter)) {
        filters.push(countryFilter);
      }
    }
  }

  function addFormTypeFilters(filters: SearchFilter[], formTypes: string[]) {
    for (const formType of formTypes) {
      let formTypeFilter: SearchFilter = SearchFilterParser.fromString("HAS_" + formType.toUpperCase().trim());
      if (formTypeFilter && !filters.includes(formTypeFilter)) {
        filters.push(formTypeFilter);
      }
    }
  }

  function getSubjectCodeFilters(searchParams: URLSearchParams): CodeBranch[] | undefined {
    let subjectCodes = searchParams.get("subject_codes");
    if (subjectCodes) {
      let newCodes: CodeBranch[] = [];
      for (const cpv of subjectCodes.split(",")) {
        let root = CpvUtil.getRoot(cpv); //because of the level 45000000 -> '45'
        let codes: string[] = SubjectCodes.filter((c) => c === root);
        if (codes.length === 1) {
          newCodes.push({code: codes[0], inclusive: true, type: CodeType.CPV});
        }
      } return newCodes;
    } else return undefined;
  }

  function getRegionCodeFilters(searchParams: URLSearchParams): CodeBranch[] | undefined {
    let regionCodes = searchParams.get("region_codes");
    if (regionCodes) {
      let newCodes: CodeBranch[] = [];
      for (const nuts of regionCodes.split(",")) {
        let codes: string[] = RegionCodes.filter((c) => c === nuts.trim().toUpperCase());
        if (codes.length === 1) {
          newCodes.push({code: codes[0], inclusive: true, type: CodeType.NUTS});
        }
      } return newCodes;
    } else return undefined;
  }

  function getAccreditationFilters(searchParams: URLSearchParams): CodeWithClass[] | undefined {
    let accreditations = searchParams.get("accreditations");
    if (accreditations) {
      let newCodes: CodeWithClass[] = [];
      for (const accreditation of accreditations.split(",")) {
        let codes = Accreditations.filter((c) => c === accreditation.trim().toUpperCase());
        if (codes.length === 1) {
          newCodes.push(CodeUtil.convertAccreditationStringsToCodeWithClass(codes)[0]);
        }
      } return newCodes;
    } else return undefined;
  }

  function validateNumber(param: string | null | undefined): number | undefined {
    if (!param) return undefined;
    let isNumber: boolean = !isNaN(Number(param));
    if (isNumber) return Number(param);
    return undefined;
  }

  function validateSortBy(param: string| null | undefined): SortBy | undefined {
    if (!param) return undefined;
    if (SortByParser.values().includes(param)){
      return SortByParser.fromString(param);
    } else return undefined;
  }

  function validateDate(param: string | null | undefined): string | undefined {
    if (!param) return undefined;
    if (DateUtil.validateDate(param)) return param;
    return undefined;
  }

  function validateDateTime(param: string | null | undefined): string | undefined {
    if (!param) return undefined;
    if (DateUtil.validateDateTime(param)) return param;
    return undefined;
  }
}
