import './App.css';
import '../styles/mixins/main.scss';
import Layout from '../components/layout';
import React from "react";

function App() {

  //This script is necessary for the Matomo tracking
    React.useEffect(() => {
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='https://cdn.matomo.cloud/tenderwolf.matomo.cloud/container_uKHhanbe.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <>
     {/*<BrowserRouter>*/}
     <Layout />
    {/*</BrowserRouter>*/}
    </>
  );
}

export default App;
