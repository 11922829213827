import React from 'react';
import styles from './index.module.scss'; 

import { Title } from './title';
import { RelatedTenderPreview } from './relatedTenderPreview';
import { CallToActionButton } from './callToActionButton';

export interface RelatedTendersProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
}

export const RelatedTenders: React.FC<RelatedTendersProps> & {
    TenderPreview: typeof RelatedTenderPreview;
    Button: typeof CallToActionButton;
} = ({
    title = 'Related Tenders',
    children,
}) => {
    const validChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child) 
            && ((   child.type === RelatedTenderPreview || 
                    child.type === CallToActionButton
                )
            )
        ){
            return child;
        }
        throw new Error('RelatedTenders only accepts RelatedTenderPreview and CallToActionButton as children');
    });
    return (<div
        className={styles['container']}
    >
        <Title>{title}</Title>
        {validChildren}
    </div>);
};

RelatedTenders.TenderPreview = RelatedTenderPreview;
RelatedTenders.Button = CallToActionButton;
