import {ResourceLanguage} from "i18next";
import {Navigation} from "./navigation";
import {Preferences} from "./preferences";
import {Time} from "./time";
import {Publications} from "./publications";
import {Codes} from "./codes";
import {ContractingAuthorityTypes} from "./contractingAuthorityTypes";
import {ContractingAuthorityMainActivities} from "./contractingAuthorityMainActivities";
import {Login} from "./login";
import {Intake} from "./intake";
import {Countries} from "./countries";
import {Settings} from "./settings";
import {SearchFilters} from "./searchFilters";
import {SearchFilterTags} from "./searchFilterTags";
import {Languages} from "./languages";
import {SavedSearchSettings} from "./savedSearchSettings";
import {DetailsSideBar} from "./detailsSideBar";
import {Error} from "./error";
import {Footer} from "./footer";
import {Features} from "./features";
import {CompanyPage} from "./companyPage";
import {RelatedTenders} from "./relatedTenders";
import {Competitors} from "./competitors";

export const NL: ResourceLanguage = {
  navigation: Navigation,
  preferences: Preferences,
  time: Time,
  publications: Publications,
  login: Login,
  intake: Intake,
  countries: Countries,
  codes: Codes,
  contractingAuthorityTypes: ContractingAuthorityTypes,
  contractingAuthorityMainActivities: ContractingAuthorityMainActivities,
  settings: Settings,
  searchFilters: SearchFilters,
  searchFilterTags: SearchFilterTags,
  languages: Languages,
  savedSearchSettings: SavedSearchSettings,
  detailsSideBar: DetailsSideBar,
  error: Error,
  footer: Footer,
  features: Features,
  companyPage: CompanyPage,
  relatedTenders: RelatedTenders,
  competitors: Competitors,
};
