import React from 'react'
import styles from './index.module.scss';

export  interface WhatIsTenderwolfButtonProps 
        extends React.HTMLAttributes<HTMLButtonElement> {
    text?: string;
}

export const WhatIsTenderwolfButton: React.FC<WhatIsTenderwolfButtonProps> = ({
    text = "What is Tenderwolf ?",
    ...props
}) => {

    const handleClick = () => {
        window.open("https://tenderwolf.com/", "_blank");
    }

    return (<button 
            className={styles["what-is-tenderwolf-button"]}
            onClick={handleClick}
            {...props}
        >
            { text }
    </button>);
}