import { apiSlice } from "./apiSlice";

export interface AIResponseInput {
  tenderUuid: string;
  fileName: string;
  languageIso: string;
}

export interface AIResponseOutput{
    response: string;
}


const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getSummary: build.mutation<AIResponseOutput, AIResponseInput>({
            query: (input: AIResponseInput) => {
                return {
                    url: `/shaggy/ai/summarize`,
                    method: 'POST',
                    body: input,
                };
            },
        })
    }),
});

export const {useGetSummaryMutation} = extendedApiSlice;
