import React, { useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { 
    Item,
    Tender,
    TenderList,
 } from './subcomponents';


export interface CompetitorsProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

export const Competitors: React.FC<CompetitorsProps> & {
    Item: typeof Item;
    Tender: typeof Tender;
    TenderList: typeof TenderList;
} = ({
    children,
    ...props
}) => {
    const { t } = useTranslation();
    return (<div 
        className={styles['card-container']}
        {...props}
    >
        <h1> {t("competitors.allCompetitorsTitle")} </h1>
        <div className={styles['competitors-container']}>
            {children}
        </div>
    </div>);
}
Competitors.Item = Item;
Competitors.Tender = Tender;
Competitors.TenderList = TenderList;