import { FlagIcon } from "../../../flags/flags";
import { getCountry } from "./getCountry";
import styles from "./index.module.scss";

interface LanguageItemProps extends React.HTMLAttributes<HTMLDivElement> {
    language: string
}

export const LanguageItem: React.FC<LanguageItemProps> = ({
    language,
    className=null,
    ...props
}) => {
    return <div
        className={className ? `${className}` : styles["language-item"]}
        {...props}
    >
            <FlagIcon country={getCountry(language)}/>
            {language.toUpperCase()}
    </div>
}
