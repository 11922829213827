import {S3FileDocument} from "../../types/tender";
import React, {useEffect, useState} from "react";
import {QueryParameters} from "../../pages/tenderDetails/main/TenderDetailsView";
import {usePostDownloadArchiveMutation} from "../../hooks/slices/tenderDetailsSlice";
import {Loader} from "../loader";

export interface DownloadFullProjectProps {
    setShowDownloadFullProjects: (b: boolean) => void;
    archiveFileName: string;
}

export const DownloadFullProject: React.FC<DownloadFullProjectProps> = ({ setShowDownloadFullProjects, archiveFileName }) => {
    let queryParameters = QueryParameters();
    const [postDownloadArchive, { isLoading: downloadingFullProject }] = usePostDownloadArchiveMutation();
    const [downloadInitiated, setDownloadInitiated] = useState(false);
    useEffect(() => {
        if (queryParameters?.uuid && !downloadInitiated) {
            //passing on an empty list of files to download all files here (which cannot be fetched from the tender)
            postDownloadArchive({ tenderUuid: queryParameters.uuid, files: [], archiveFileName: archiveFileName});
            setDownloadInitiated(true);
        }
    }, [queryParameters, downloadInitiated, postDownloadArchive]);
    useEffect(() => {
        if (!downloadingFullProject && downloadInitiated) {
            setShowDownloadFullProjects(false);
        }
    }, [downloadingFullProject, downloadInitiated]);

    if (downloadingFullProject) return <Loader/>;

    return <></>;
};