import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useTranslation} from "react-i18next";
import {SearchFilter} from "../../../../consts/SearchFilter";
import {
    resetDeadlineFilter,
    resetOpeningReportOrAwardDateFilter,
    resetPublicationDateFilter,
    SearchFilterState,
    updateSearchFilters
} from "../../../../hooks/slices/tenderSearchSlice";
import {SearchFilterUtil} from "../../../../utils/searchFilters";
import {QueryFilterButton} from "./QueryFilterButton";
import {DateFilterButton} from "./DateFilterButton";
import {DateUtil} from "../../../../utils/date";
import {SearchFilterButton, SearchFilterButtonSkeleton} from "./SearchFilterButton";
import {CodeBranchFilterButtons, CodeWithClassFilterButtons} from "./CodeFilterButtons";
import {LabelFilterButtons} from "./LabelFilterButtons";
import {ResetAllFiltersButton} from "./ResetAllFiltersButton";
import {CompanyFilterButtons} from "./CompanyFilterButtons";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";
import {SimilarTenderFilterButton} from "./SimilarTenderFilterButton";
import {motion} from "framer-motion";
import {GenericSearchPageProps} from "../../GenericSearchPage";
import {SearchType} from "../../../../consts/searchType";
import {SavedSearchFilterButton, SavedSearchFilterButtons} from "./SavedSearchFilterButton";
import {RaiseSavedSearches, SavedSearchesInStore} from "../../../../hooks/raiseSavedSearches";
import {SavedSearchUtil} from "../../../../utils/savedsearches";

export const SearchFilterButtons: React.FC<GenericSearchPageProps> = (props) => {
  const dispatch = useAppDispatch();
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const { t, i18n } = useTranslation();
  const savedSearches: SavedSearchesInStore = RaiseSavedSearches();
  const allProfileNames: string[] = SavedSearchUtil.getSavedSearchNames(savedSearches, t, i18n.language);
  const searchFilters: SearchFilterState = useAppSelector((state) => state.tenderSearch.searchFilters);
  const currentlySearchingOpportunities: boolean = props.searchConfiguration.sidebar.queryType === SearchType.OPPORTUNITIES;
  const removeSearchFilter = (searchFilter: SearchFilter) => {
    dispatch(updateSearchFilters({ selectedFilters: searchFilters.selectedFilters.filter((s) => s !== searchFilter), userInteractions: userInteractions }));
  };
  let hiddenFilters: SearchFilter[] = props.searchConfiguration.info.hiddenFilters
    ? props.searchConfiguration.info.hiddenFilters
    : [];
  let lockedFilters: SearchFilter[] = props.searchConfiguration.info.lockedFilters
    ? props.searchConfiguration.info.lockedFilters
    : [];
  let hiddenOrLockedFilters: SearchFilter[] = hiddenFilters.concat(lockedFilters);
  let shownGenericSearchFilters: SearchFilter[] = Array.from(
    new Set(searchFilters.selectedFilters.filter((f) => !hiddenOrLockedFilters.includes(f)))
  );
  const resetQuery = () => {
    dispatch(updateSearchFilters({ query: "", userInteractions: userInteractions }));
  };
  let currentDateFilter = SearchFilterUtil.findPublicationDateFilters(searchFilters.selectedFilters);
  let hidePublicationDateFilter: boolean = currentDateFilter.filter((f) => hiddenFilters.includes(f)).length > 0;
  let publicationDateFilterCanBeRemoved: boolean = currentDateFilter.filter((f) => lockedFilters.includes(f)).length === 0;
  const savedSearchIsActive = (name: string): boolean => {
        return searchFilters.savedSearches ? searchFilters.savedSearches?.includes(name) : false;
  }
  const removeSavedSearch = (name: string) => {
      let filteredSavedSearches = searchFilters.savedSearches?.filter((s) => s !== name);
      if (filteredSavedSearches && filteredSavedSearches.length > 0){
          dispatch(updateSearchFilters({ savedSearches: filteredSavedSearches, userInteractions: userInteractions }));
          return true;
      } return false;
  }
  const addSavedSearch = (name: string) => {
        let newSavedSearches = [...searchFilters.savedSearches ? searchFilters.savedSearches : [], name];
        dispatch(updateSearchFilters({ savedSearches: newSavedSearches, userInteractions: userInteractions }));
        return true;
  }
  let showSavedSearchFilterButtons = (searchFilters.savedSearches && searchFilters.savedSearches.length > 0);
  let companyVatNumbers = searchFilters.participantVatNumbers ? searchFilters.participantVatNumbers : [];
  if (searchFilters.contractingAuthorityVatNumbers) companyVatNumbers = companyVatNumbers.concat(searchFilters.contractingAuthorityVatNumbers);
  let numberOfFiltersShown: number = countShownFilters(searchFilters, shownGenericSearchFilters, currentlySearchingOpportunities);
  if (numberOfFiltersShown === 0 && !currentlySearchingOpportunities) return <></>;
  return (
    <>  {props.searchConfiguration.info.showFilterButtons && showSavedSearchFilterButtons &&
       <SavedSearchFilterButtons allProfileNames={allProfileNames} savedSearchIsActive={savedSearchIsActive} addSavedSearch={addSavedSearch} removeSavedSearch={removeSavedSearch}/>
    }
        {props.searchConfiguration.info.showFilterButtons && (
            <motion.div className="search-filter-button-alignment"
                        variants={{hidden: {opacity: 0}, visible: {opacity: 1, transition: {staggerChildren: 0.2},}}}
                    initial="hidden"
                    animate="visible">
            {searchFilters.similarTender && (<SimilarTenderFilterButton similarTender={searchFilters.similarTender}/>)}
            <QueryFilterButton query={searchFilters.query} resetQuery={resetQuery} />
          {!hidePublicationDateFilter && (
            <DateFilterButton
              label={t("publications.publicationDateTitle")}
              start={searchFilters.startPublicationDate}
              end={searchFilters.endPublicationDate}
              canRemove={publicationDateFilterCanBeRemoved}
              removeDateFilters={() => dispatch(resetPublicationDateFilter())}
            />
          )}
            <DateFilterButton
              label={t("publications.deadlineTitle")}
              start={DateUtil.convertDateTimeStringToDateString(searchFilters.startDeadlineDate)}
              end={DateUtil.convertDateTimeStringToDateString(searchFilters.endDeadlineDate)}
              canRemove={true}
              removeDateFilters={() => dispatch(resetDeadlineFilter())}
            />
            <DateFilterButton
                label={t("publications.awardPublicationDateTitle")}
                start={DateUtil.convertDateTimeStringToDateString(searchFilters.awardOrOpeningReportPublicationStartDate)}
                end={DateUtil.convertDateTimeStringToDateString(searchFilters.awardOrOpeningReportPublicationEndDate)}
                canRemove={true}
                removeDateFilters={() => dispatch(resetOpeningReportOrAwardDateFilter())}
            />
          {shownGenericSearchFilters.sort().map((filter, i) => (
            <SearchFilterButton
              key={i}
              searchFilter={filter}
              removeSearchFilter={removeSearchFilter}
              canBeRemoved={true}
              searchResultsConfiguration={props.searchConfiguration.info}
            />
          ))}
          {searchFilters.subjectCodes && <CodeBranchFilterButtons codes={searchFilters.subjectCodes} />}
          {searchFilters.regionCodes && <CodeBranchFilterButtons codes={searchFilters.regionCodes} />}
          {searchFilters.accreditations && <CodeWithClassFilterButtons codes={searchFilters.accreditations} />}
          {searchFilters.labelFilters && (
            <LabelFilterButtons labelFilters={searchFilters.labelFilters} />
          )}
          {companyVatNumbers && (
                <CompanyFilterButtons vatNumberFilters={companyVatNumbers} />
           )}
          {numberOfFiltersShown > 1 && <ResetAllFiltersButton/>}
        </motion.div>
      )}
    </>
  );
};

function countShownFilters(f: SearchFilterState, g: SearchFilter[], currentlySearchingOpportunities: boolean) {
    let count = g.length;
    if (f.query !== "") count++;
    if (f.subjectCodes != null && f.subjectCodes.length > 0) count+=f.subjectCodes.length;
    if (f.regionCodes != null && f.regionCodes.length > 0) count+=f.regionCodes.length;
    if (f.accreditations != null && f.accreditations.length > 0) count+=f.accreditations.length;
    if (f.labelFilters != null && f.labelFilters.length > 0) count += f.labelFilters.length;
    if (f.similarTender != null ) count+=1;
    if(!currentlySearchingOpportunities){
        if (f.startPublicationDate != null) count+= 1;
        if (f.endPublicationDate != null) count+= 1;
    }
    if (f.startDeadlineDate != null ) count+= 1;
    if (f.endDeadlineDate != null ) count+= 1;
    if (f.awardOrOpeningReportPublicationStartDate != null ) count+= 1;
    if (f.awardOrOpeningReportPublicationEndDate != null ) count+= 1;
    //searchFilters.participantVatNumbers
    // searchFilters.contractingAuthorityVatNumbers
    if (f.participantVatNumbers != null && f.participantVatNumbers.length > 0) count+=f.participantVatNumbers.length;
    if (f.contractingAuthorityVatNumbers != null && f.contractingAuthorityVatNumbers.length > 0) count+=f.contractingAuthorityVatNumbers.length;
    return count;
}

export const SearchFilterButtonsSkeleton = () => {
    return <div className="search-filter-button-alignment"><SearchFilterButtonSkeleton/></div>;
};