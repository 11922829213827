import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './index.module.scss';
import TenderWolfLogo from '../../assets/logo/black-logo-row.svg';
import { LanguageDropdown } from './subcomponents';


interface HeaderProps {
    showLanguagesDropdown?: Boolean;
}
export const LoginOrSignupHeader: React.FC<HeaderProps> = ({
    showLanguagesDropdown=true,
}) => {
    const { t } = useTranslation();

    return (
      <>
        <div>
          <header>
            <div className={styles["container"]}>
              <div className={styles["header-alignment"]}>
                <div className={styles["logo"]}>
                  <a href="https://tenderwolf.com/product">
                    <img 
                        src={TenderWolfLogo} 
                        alt="TenderWolfLogo"
                    />
                  </a>
                </div>
                <div className={styles["spacer"]}></div>
                <div 
                    className={styles["language-select"]}
                >
                    { showLanguagesDropdown && 
                      <LanguageDropdown languages={['en', 'fr', 'nl']}/>}
                </div>
              </div>
            </div>
          </header>
        </div>
      </>
    );
}