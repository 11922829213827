import React, {useEffect, useState} from "react";
import "./company.scss";
import {useTranslation} from "react-i18next";
import {
  CompanyDto,
  CompanyPageDto,
  CompanyRelationDto,
  CompanySuggestion,
  RecentTenderDto,
  useGetCompanyPageQuery
} from "../../hooks/slices/companySlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {CodeUtil, CpvUtil} from "../../utils/codes";
import {CodeType} from "../../types/code";
import {Loader} from "../../components/loader";
import {useNavigate} from "react-router-dom";
import {QueryParameters} from "../tenderDetails/main/TenderDetailsView";
import {RaiseUser, UserLanguage} from "../../hooks/raiseUser";
import {ErrorPage} from "../error";
import {showErrorPopup, updateSnacc} from "../../hooks/slices/snaccSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {StarFillIcon, StarIcon} from "../../components/icons";
import {
  addCompanyPageInteraction,
  addUserInteraction,
  CompanyPageInteraction,
  removeCompanyPageInteraction,
  removeUserInteraction,
  useAddUserInteractionMutation,
  useRemoveUserInteractionMutation,
  UserInteraction,
  UserInteractionInput
} from "../../hooks/slices/userSlice";
import {UserInteractionType} from "../../consts/UserInteractionType";
import {MultilingualFieldUtil} from "../../utils/multilingualfield";
import {t} from "i18next";

export const CompanyPage: React.FC = () => {
  let queryParameters = QueryParameters();
  const {i18n} = useTranslation();
  // const lang = i18n.language; // something like en-US
  let uuid: string = queryParameters.uuid;
  let languageIso = queryParameters.tenderLanguage ? queryParameters.tenderLanguage : UserLanguage(i18n.language);
  if (!uuid) return <ErrorPage></ErrorPage>;
  else return <CompanyPageWithData uuid={uuid} languageIso={languageIso} />;
};

interface CompanyPageWithDataProps {
  uuid: string;
  languageIso: string;
}

export const CompanyPageWithData: React.FC<CompanyPageWithDataProps> = ({ uuid, languageIso }) => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, error, isError } = useGetCompanyPageQuery({ uuid: uuid } ?? skipToken);
  const [companyPage, setCompanyPage] = useState<CompanyPageDto | null>(null);
  const autocomplete = (suggestion: CompanySuggestion) => {
    setCompanyPage(null);
    navigate("/company/" + suggestion.uuid);
  };
  useEffect(() => {
    if (isSuccess && data) {
      setCompanyPage(data);
    } if (error && isError){
      dispatch(updateSnacc({
            message: t("companyPage.unknown_company") + " " + uuid,
            severity: "error",
            open: true
          }
      ))
    }
  }, [data, isSuccess, error, isError]);

  return (
    <>
      {isLoading && <Loader />}
      {/*container adds margins left and right*/}
      <div className={"container"}>
        <div className="company-section-alignment">
          <div>
            {companyPage && (
              <div className="company-info-section-alignment">
                <CompanyInfoCard {...companyPage} />
                {/*<div style={{display: "grid", gridTemplateRows: "1fr 1fr"}}>*/}
                {companyPage.competitors && companyPage.competitors.length > 0 && (
                  <CompanyCompetitorsCard {...companyPage} />
                )}
                {companyPage.tendersRecentlyWon && companyPage.tendersRecentlyWon.length > 0 && (
                  <CompanyRecentActivityCard tenders={companyPage.tendersRecentlyWon} title={t("companyPage.recently_won_section_title")} />
                )}
                {companyPage.tendersRecentlyParticipatedIn && companyPage.tendersRecentlyParticipatedIn.length > 0 && (
                    <CompanyRecentActivityCard tenders={companyPage.tendersRecentlyParticipatedIn} title={t("companyPage.recently_participated_in_section_title")}/>
                )}
                {companyPage.tendersContractedOpen && companyPage.tendersContractedOpen.length > 0 && (
                    <CompanyRecentActivityCard tenders={companyPage.tendersContractedOpen} title={t("companyPage.tenders_contracted_open_title")}/>
                )}
                {companyPage.tendersContractedClosed && companyPage.tendersContractedClosed.length > 0 && (
                    <CompanyRecentActivityCard tenders={companyPage.tendersContractedClosed} title={t("companyPage.tenders_contracted_closed_title")}/>
                )}
                {/*</div>*/}

                {/*{companyPage.competitors && companyPage.competitors.length > 0 && <MainCompetitorsAndRecentActivity {...companyPage} />}*/}
                {companyPage.buyers && companyPage.buyers.length > 0 && <CompanyRelationsCard relations={companyPage.buyers} title={t("companyPage.main_buyers_section_title")} />}
                {companyPage.suppliers && companyPage.suppliers.length > 0 && <CompanyRelationsCard relations={companyPage.suppliers} title={t("companyPage.main_suppliers_section_title")} />}
                {companyPage.mainActivities && companyPage.mainActivities.length > 0 && (
                  <CompanyMainActivitiesCard {...companyPage} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface CompanyPageCardProps {
  title: string;
}

export const CompanyPageCard: React.FC<CompanyPageCardProps> = ({ title, children }) => {
  return (
    <div className={"company-info-card"}>
      <div className="company-info-card-content">
        <div className="company-info-card-title">
          <button>{title}</button>
        </div>
        <div className="company-info-card-body">{children}</div>
      </div>
    </div>
  );
};

const CompanyInfoCard: React.FC<CompanyPageDto> = ({ company }) => {
  let { t } = useTranslation();
  let description: CompanyDetailsContentItem[] = getCompanyDescriptionRows(company);
  const combinedActivities: CompanyDetailsContentItem[] = getCompanyActivities(company);
  let accreditations: CompanyDetailsContentItem[] = getCompanyAccreditations(company);
  let accreditationsRequirePreview: boolean = accreditations.length > 6;
  let accreditationsPreview = accreditationsRequirePreview ? accreditations.slice(0, 6) : accreditations;
  const [accreditationsUnfolded, setAccreditationsUnfolded] = useState(false);
  return (
    <>
      <CompanyPageCard
        title={t("companyPage.company_information_section_title")}
        children={
          <>
            <FollowCompanyButton company={company} />
            <CompanyDetailsSection title={company.name} content={description} />
            {combinedActivities && combinedActivities.length > 0 && (
              <>
                <CompanyDetailsSection
                  title={t("companyPage.main_activities_section_title")}
                  content={combinedActivities}
                  key={t("companyPage.main_activities_section_title") + " - " + company.uuid}
                />
                {accreditations && accreditations.length > 0 &&
                    <CompanyDetailsSection
                        title={t("companyPage.accreditations_title")}
                        content={accreditationsUnfolded ? accreditations : accreditationsPreview}
                        key={t("companyPage.accreditations_title") + " - " + company.uuid}
                        scroll={false}
                    />}
                {accreditationsRequirePreview &&
                    <div className={"company-info-body-section"} style={{marginTop: '-12px', cursor: 'pointer'}}
                         onClick={() => setAccreditationsUnfolded(!accreditationsUnfolded)}>
                      <div>
                        <div>
                          <p>...</p>
                        </div>
                      </div>
                    </div>
                }

              </>
            )}
          </>
        }
      />
    </>
  );
};

const FollowCompanyButton: React.FC<{ company: CompanyDto }> = ({company}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userUuid = useAppSelector((state) => state.user.uuid);
  const userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const companyPageInteractions: CompanyPageInteraction[] = RaiseUser().user?.companyPageInteractions ?? [];
  const [addUserInteractionToDb, {
    isError: addUserInteractionsError,
    error: addUserInteractionsErrorObject
  }] = useAddUserInteractionMutation();
  const [removeUserInteractionFromDb, {
    isError: removeUserInteractionsError,
    error: removeUserInteractionsErrorObject
  }] = useRemoveUserInteractionMutation();

  const findCompanyPageUuid = (): string | undefined => {
    if (userInteractions.length === 0 || companyPageInteractions.length === 0) return undefined;
    else return companyPageInteractions.find(cp => cp.companyPageVatNumber === company.vatNumber)?.companyPageUuid;
  }

  const isFollowedCompany = (): boolean => {return findCompanyPageUuid() != null;}

  let followCompany = () => {
    if (userUuid && company.uuid) {
      let interaction: UserInteraction = { uuid: company.uuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: interaction };
      addUserInteractionToDb(interactionInput); //update DB
      dispatch(addUserInteraction(interactionInput.interaction)); //update search state
      dispatch(addCompanyPageInteraction({companyPageUuid: company.uuid, companyPageVatNumber: company.vatNumber, companyPageName: company.name})); //update search state
    }
  }

  let unfollowCompany = () => {
    if (userUuid && company.uuid) {
      let interaction: UserInteraction = { uuid: company.uuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: interaction };
      removeUserInteractionFromDb(interactionInput); //update DB
      dispatch(removeUserInteraction(interactionInput.interaction)); //update search state
      dispatch(removeCompanyPageInteraction({companyPageUuid: company.uuid})); //update search state
    }
  }

  useEffect(() => {
    if (removeUserInteractionsError && removeUserInteractionsErrorObject) {
      dispatch(showErrorPopup(removeUserInteractionsErrorObject));
    } else if (addUserInteractionsError && addUserInteractionsErrorObject) {
      dispatch(showErrorPopup(addUserInteractionsErrorObject));
    }
  }, [removeUserInteractionsError, removeUserInteractionsErrorObject]);


  return <span className={"follow-button"}>
            {isFollowedCompany() && <span onClick={() => unfollowCompany()}><StarFillIcon/></span>}
            {!isFollowedCompany() && <span onClick={() => followCompany()} title={t("companyPage.follow_company")}><StarIcon/></span>}
          </span>;
};

export function getCompanyDescriptionRows(company: CompanyDto): CompanyDetailsContentItem[] {
  let description: CompanyDetailsContentItem[] = [];
  if (company.vatNumber != null && company.vatNumber.length > 2)
    description.push({ value: company.vatNumber.slice(2) });
  if (company.street != null) description.push({ value: company.street });
  if (company.postalCode != null && company.city != null)
    description.push({ value: company.postalCode + " " + company.city });
  if (company.website != null) description.push({ value: company.website, href: company.website });
  if (company.phone != null) description.push({ value: company.phone });
  return description;
}

//There are always at most two rsz activities and we're only interested in showing three entries at most, so we take the
//rsz activities first (these are more interesting) and then complete the list with btw activities that are not overlapping
//with the rsz activities.
export function getCompanyActivities(company: CompanyDto): CompanyDetailsContentItem[]{
  const rszActivities = company.activities
      .filter((a) => a.type === 'RSZ')
      .slice(0, 2)
      .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
  const numBtwActivities = rszActivities.length === 1 ? 2 : 1; // If there is only one RSZ activity, show 2 BTW activities, otherwise show 1
  const btwActivities = company.activities
      .filter((a) => a.type === 'BTW' && !rszActivities.includes((a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description)))
      .slice(0, numBtwActivities)
      .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
  const combinedActivities: CompanyDetailsContentItem[] = [...rszActivities, ...btwActivities].map(activity => ({
    value: activity
  })); return combinedActivities;
}

export function getCompanyAccreditations(company: CompanyDto): CompanyDetailsContentItem[]{
  const accreditations: string[] = company.activities
      .filter((a) => a.type === 'ACCREDITATION')
      .map(a => a.description);
  accreditations.sort();
  return accreditations.map(activity => ({value: activity}))
}

const CompanyCompetitorsCard: React.FC<CompanyPageDto> = ({ competitors }) => {
  let { t } = useTranslation();
  const competitorContentItems: CompanyDetailsContentItem[] = competitors.map((c) => {
    return {
      value: `${c.relation.name}`,
      href: `/company/${c.relation.uuid}`,
      followCompanyButton: c.relation
    };
  });

  return (
    <>
      <CompanyPageCard
        title={t("companyPage.main_competitors_section_title")}
        children={
          <>
            <CompanyDetailsSection content={competitorContentItems} />
          </>
        }
      />
    </>
  );
};

const CompanyRelationsCard: React.FC<{relations: CompanyRelationDto[], title: string}> = ({ relations, title }) => {
  let { t } = useTranslation();
  let buyerContentItems: CompanyDetailsContentItem[] = [...relations]
      .sort((a, b) => {
        if(a == null || b == null) return 0;
        return b.sharedProjects - a.sharedProjects})
      .map((c, i) => ({
        value: c.relation.name + (c.sharedProjects > 0 ? " (" + c.sharedProjects + ")" : ""),
        href: "/company/" + c.relation.uuid
  }));
  return (
    <>
      <CompanyPageCard
        title={title}
        children={
          <>
            <CompanyDetailsSection content={buyerContentItems} scroll={true} />
          </>
        }
      />
    </>
  );
};

//todo take into account EN translations?
const CompanyRecentActivityCard: React.FC<{tenders: RecentTenderDto[], title: string}> = ({tenders, title}) => {
  let { t } = useTranslation();
  let lines = 20;
  let totalHits = tenders.length > 0 ? tenders[0].totalHits : 0;
  let recentTenderContentItems: CompanyDetailsContentItem[] = tenders.map((c, i) => ({
    value: (i + 1) + ". " + MultilingualFieldUtil.translate(c.title, "NL"),
    href: "/tender/" + c.uuid
  }));
  let moreHits: boolean = totalHits > recentTenderContentItems.length;
  if (recentTenderContentItems.length > lines) recentTenderContentItems = recentTenderContentItems.slice(0, lines);
  if (moreHits) {
    recentTenderContentItems.push({ value: "_____________________________" });
    recentTenderContentItems.push({ value: t("companyPage.total_results") + ": " + totalHits  });
  }
  return (
    <>
      <CompanyPageCard
        title={title}
        children={
          <>
            <CompanyDetailsSection content={recentTenderContentItems} scroll={true} />
          </>
        }
      />
    </>
  );
};

const CompanyMainActivitiesCard: React.FC<CompanyPageDto> = ({ tendersRecentlyWon, tendersRecentlyParticipatedIn, mainActivities }) => {
  let { t } = useTranslation();
  let recentTenders = [...tendersRecentlyWon].concat(tendersRecentlyParticipatedIn);
  let totalHits = recentTenders.length > 0 ? recentTenders[0].totalHits : 0;
  let activityContentItems: CompanyDetailsContentItem[] = mainActivities
      .filter(c => c.count >= totalHits / 10)
      .map((c, i) => ({
    value: CodeUtil.getCodeTranslation(CpvUtil.getCpvFullCode(c.cpvRoot.code), CodeType.CPV, t) + " (" + c.count + ")"
  }));
  return (
    <>
      <CompanyPageCard
        title={t("companyPage.main_activities_section_title")}
        children={
          <>
            <CompanyDetailsSection content={activityContentItems} scroll={true} />
          </>
        }
      />
    </>
  );
};

export interface CompanyDetailsProps {
  title?: string;
  content?: CompanyDetailsContentItem[];
  columns?: boolean;
  scroll?: boolean;
}

export interface CompanyDetailsContentItem {
  value: string;
  href?: string;
  followCompanyButton?: CompanyDto;
}

export const CompanyDetailsSection: React.FC<CompanyDetailsProps> = ({ title, content, columns, scroll }) => {
  const containsNullValues = (c: CompanyDetailsContentItem[]) => c.some((c) => c.value === null || c.value === undefined);
  const addProtocolToUrl = (url: string) => {
    if (url.startsWith("www")) {
      return "https://" + url;
    } return url;
  }
  return (
    <>
      <div className={"company-info-body-section"}>
        {title && <h2>{title}</h2>}
        <div className={(scroll ? " scroll" : "") + (columns ? " columns" : "")}>
          {content &&
              !containsNullValues(content) &&
              content.map((c, i) => (
                  <div style={{display: 'grid', gridTemplateColumns: '9fr 1fr'}} key={i}>
                    <p>
                      {c.href ?
                          <a className={"hover-highlight"} href={addProtocolToUrl(c.href)}>{c.value}</a> : c.value}
                    </p>
                    {c.followCompanyButton && <FollowCompanyButton company={c.followCompanyButton}/>}
                  </div>

              ))}
        </div>
      </div>
    </>
  );
};
