import React, {useEffect, useState} from "react";
import styles from "./signup-accelerated.module.scss";
import {useAppDispatch} from "../../../app/hooks";
import {updateToken} from "../../../hooks/slices/loginSlice";
import {showCustomErrorPopup} from "../../../hooks/slices/snaccSlice";
import {getI18n, useTranslation} from "react-i18next";
import {Form} from "../../../components/form/Form";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {usePostSignupStartMutation} from "../../../hooks/slices/signupSlice";
import {EmailUtil} from "../../../utils/email";
import {ValidatedInput} from "../../../components/validatedInput/validatedInput";
import {persistor} from "../../../app/store";
import {Loader} from "../../../components/loader";
import {useGetAllPlanTemplatesQuery} from "../../../hooks/slices/currentPlanSlice";
import {PasswordUtil} from "../../../utils/password";
import {PlanUtil} from "../../../utils/plans";
import {Duration} from "../../../types/tender";
import {useSignupErrorHandler} from "../SignupErrorHandler";
import {useNavigateToOpportunitiesIfSignupAlreadyFinished} from "../SignupFinishedHandler";
import {PrivacyPolicyOverlay, TermsAndConditionsOverlay} from "../start";
import {useGetTenderPreviewQuery} from "../../../hooks/slices/tenderDetailsSlice";
import {TenderDetailsPage} from "../../tenderDetails/main/TenderDetailsPage";
import {ErrorPage} from "../../error";
import {Banner} from "../../../components/banner/Banner";
import {UserLanguage} from "../../../hooks/raiseUser";
import {Modal} from "../../../components/modal/";
import { Competitors } from "../../../components/competitors"
import { Country } from "../../../consts/countries";
import { MultilingualFieldUtil } from "../../../utils/multilingualfield";
import { LanguageItem } from "../../../components/loginHeader/subcomponents";
import { Language } from "../../../consts/languages";
import { HeaderButtons } from "./headerButtons";
import { WhatIsTenderwolfButton } from "./whatIsTenderwolfButton";
import { FollowYourCompetitorsButton } from "./followYourCompetitorsButton";

export const SignupAccelerated: React.FC = () => {
    useNavigateToOpportunitiesIfSignupAlreadyFinished();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    let tenderUuid = searchParams.get("tenderUuid");
    let vatNumber = searchParams.get("vatNumber");
    let companyUuid = searchParams.get("companyUuid");
    let showCompetitors = searchParams.has("showCompetitors");
    const [postClient, {data: signupStartResponse, isSuccess, isError, error}] = usePostSignupStartMutation();
    const {data: availablePlanTemplates, isLoading} = useGetAllPlanTemplatesQuery();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [highlightEmptyFields, setHighlightEmptyFields] = useState(false);
    const [highlightInvalidFields, setHighlightInvalidFields] = useState(false);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [selectedCompetitorIndex,setSelectedCompetitorIndex] = useState<number|null>(0);
    const showNCompetitors = 2;
    const {t, i18n} = useTranslation();
    let languageIso = UserLanguage(i18n.language);
    // on submission of the login form, we call the postClient trigger to
    // submit the client data to nighteyes and wait for a JWT in return
    const onSubmit = (
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        confirmPassword: string
    ) => {
        setHighlightEmptyFields(false);
        setHighlightInvalidFields(false);
        setPasswordMatch(true);
        if (firstName === "" || lastName === "" || email === "" || password === "" || confirmPassword === "") {
            setHighlightEmptyFields(true);
            dispatch(showCustomErrorPopup(t("intake.fill_in_all_fields")));
        } else if (!EmailUtil.emailAddressIsValid(email)) {
            setHighlightInvalidFields(true);
            dispatch(showCustomErrorPopup(t("intake.invalid_email")));
        } else if (PasswordUtil.passwordIsInvalid(password)) {
            setHighlightInvalidFields(true);
            dispatch(showCustomErrorPopup(t("intake.password_is_invalid")));
        } else if (password !== confirmPassword) {
            setPasswordMatch(false);
            dispatch(showCustomErrorPopup(t("intake.passwords_dont_match")));
        } else if (passwordMatch && !highlightEmptyFields && tenderUuid) {
            let locale: string = getI18n().language;
            if (vatNumber) postClient({
                firstName,
                lastName,
                email,
                password,
                locale,
                governmentClient: false,
                vatNumber: vatNumber,
                tenderUuid: tenderUuid
            });
            if (companyUuid) postClient({
                firstName,
                lastName,
                email,
                password,
                locale,
                governmentClient: false,
                companyUuid: companyUuid,
                tenderUuid: tenderUuid
            });
            setShowLoader(true);
        }
    };
    const navigateOrShowPopup = useSignupErrorHandler();
    useEffect(() => {
        if (isSuccess && signupStartResponse) {
            setShowLoader(false);
            // pass the data from the successful login to the redux store
            persistor
                .purge() // resets the state store, in case there is already another client in the store
                .then(() => {
                    persistor.flush();
                })
                .then(() => dispatch(updateToken(signupStartResponse.token)));
            if (signupStartResponse.signupComplete && tenderUuid) {
                navigate("/tender/" + tenderUuid + "?add_to_shortlist=true");
            }
        } else if (isError && error) {
            setShowLoader(false);
            // show an error on the snackbar with a failed login
            navigateOrShowPopup(error);
        }
    }, [isSuccess, signupStartResponse, isError, error, dispatch]);

    if (isLoading || availablePlanTemplates == null || availablePlanTemplates.planTemplates == null) return <></>;
    if (!tenderUuid || (!vatNumber && !companyUuid)) return <ErrorPage/>
    return (
        <div>
            {showLoader && <Loader/>}
            {showPrivacyPolicy && !showTermsAndConditions && <div className='layout-main-banner'>
                <div className='container'><PrivacyPolicyOverlay setOverlay={setShowPrivacyPolicy}/></div>
            </div>}
            {showTermsAndConditions && !showPrivacyPolicy && (<div className='layout-main-banner'>
                <div className='container'><TermsAndConditionsOverlay setOverlay={setShowTermsAndConditions}/></div>
            </div>)}
            {!showPrivacyPolicy && !showTermsAndConditions &&
                <>
                    <Modal 
                        isVisible={showRegisterForm} 
                        setIsVisible={setShowRegisterForm}
                        classNameDialog={` ${styles["register-box"]}`}
                    >
                        <RegisterForm
                            onSubmit={onSubmit}
                            passwordMatch={passwordMatch}
                            highlightEmptyFields={highlightEmptyFields}
                            highlightInvalidFields={highlightInvalidFields}
                            trialPlanDuration={PlanUtil.getPlanDuration("Trial", availablePlanTemplates.planTemplates)}
                            showPrivacyPolicy={() => setShowPrivacyPolicy(true)}
                            showTermsAndConditions={() => setShowTermsAndConditions(true)}
                            close={() => setShowRegisterForm(false)}
                        />
                    </Modal>
                    {/* <Banner message={[t("intake.banner_message_first_line"), t("intake.banner_message_second_line")]}
                                handleClick={() => setShowRegisterForm(true)}/> */}
                    <Header
                        tenderUuid={tenderUuid}
                        companyUuid={companyUuid}
                        language={languageIso}
                    />
                    {showCompetitors && <CompetitorsOverview
                        tenderUuid={tenderUuid}
                        companyUuid={companyUuid}
                        language={languageIso}
                        setShowRegisterForm={setShowRegisterForm}    
                    />}
                    { !showCompetitors && <TenderPreview 
                        tenderUuid={tenderUuid}
                        companyUuid={companyUuid}
                        language={languageIso}
                        handleCallToRegister={() => setShowRegisterForm(true)}
                    />}
                </>}
        </div>
    );
};


interface RegisterFormProps {
    onSubmit: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string) => void;
    passwordMatch: boolean;
    highlightEmptyFields: boolean;
    highlightInvalidFields: boolean;
    trialPlanDuration: Duration;
    showTermsAndConditions: () => void;
    showPrivacyPolicy: () => void;
    close: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
                                                       onSubmit,
                                                       passwordMatch,
                                                       highlightEmptyFields,
                                                       highlightInvalidFields,
                                                       trialPlanDuration,
                                                       showTermsAndConditions,
                                                       showPrivacyPolicy,
                                                       close
                                                   }) => {
    const [searchParams] = useSearchParams();
    let emailParam = searchParams.get("email");
    let nameParam = searchParams.get("name");
    const {t} = useTranslation();

    const [firstName, setFirstName] = useState(nameParam != null ? nameParam.split(" ")[0] : "");
    const onFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.currentTarget.value);
    };

    const [lastName, setLastName] = useState(
        nameParam != null && nameParam.split(" ").length > 1 ? nameParam.split(" ")[1] : ""
    );
    const onLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.currentTarget.value);
    };

    const [email, setEmail] = useState(emailParam != null ? emailParam : "");
    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.currentTarget.value);
    };

    const [password, setPassword] = useState("");
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.currentTarget.value);
    };

    const [confirmPassword, setConfirmPassword] = useState("");
    const onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };

    const [termsAgree, setTermsAgree] = useState(false);
    const onTermsAgreeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTermsAgree(e.currentTarget.checked);
    };

    return (
    <Form
        onSubmit={() => {
            onSubmit(firstName, lastName, email, password, confirmPassword);
        }}
    >
        <div
            className={styles["child-text-all-alignment"]}
        ><p>{`
            ${t("intake.banner_message_first_line")}
            ${t("intake.banner_message_second_line")}
        `}</p></div>
        <div className={styles["input-first-grid"]}>
            <div className={styles["input-first-grid-items"]}>
                <ValidatedInput
                    value={firstName}
                    placeholder={t("intake.first_name")}
                    isInvalid={firstName === ""}
                    highlightIfInvalid={highlightEmptyFields}
                    onChange={onFirstNameChange}
                    testId={"intake-first-name"}
                    focus={true}
                />
            </div>
            <div className={styles["input-first-grid-items"]}>
                <ValidatedInput
                    value={lastName}
                    placeholder={t("intake.last_name")}
                    isInvalid={lastName === ""}
                    highlightIfInvalid={highlightEmptyFields}
                    onChange={onLastNameChange}
                    testId={"intake-second-name"}
                />
            </div>
        </div>
        <div className={styles["input-bottom-alignment"]}>
            <ValidatedInput
                value={email}
                placeholder={t("intake.email")}
                isInvalid={!EmailUtil.emailAddressIsValid(email) || email === ""}
                highlightIfInvalid={highlightInvalidFields || highlightEmptyFields}
                onChange={onEmailChange}
                testId={"intake-email"}
            />
        </div>
        <div className={styles["input-bottom-alignment"]}>
            <ValidatedInput
                value={password}
                placeholder={t("intake.enter_password")}
                isInvalid={!passwordMatch || PasswordUtil.passwordIsInvalid(password)}
                highlightIfInvalid={!passwordMatch || highlightEmptyFields || highlightInvalidFields}
                onChange={onPasswordChange}
                type={"password"}
                testId={"intake-password"}
            />
        </div>
        <div className={styles["input-bottom-alignment"]}>
            <ValidatedInput
                value={confirmPassword}
                placeholder={t("intake.confirm_password")}
                isInvalid={!passwordMatch || confirmPassword === ""}
                highlightIfInvalid={!passwordMatch || highlightEmptyFields}
                onChange={onConfirmPasswordChange}
                type={"password"}
                testId={"intake-password-confirm"}
            />
            {PasswordUtil.passwordIsInvalid(password) && highlightInvalidFields && (
                <div className={styles["input-message"]}>
                    <p>{t("intake.valid_password_description")}</p>
                </div>
            )}
        </div>

        <div className={styles["checkbox-text-alignment"]}>
            <div>
                <div className={styles["child-product"]}>
                    <input type="checkbox" id="terms" data-testid={"intake-terms"}
                            onChange={onTermsAgreeChange}/>
                    <label htmlFor="terms">
    <span>
    {t("intake.agree")}
        <a onClick={() => showPrivacyPolicy()}>{t("intake.privacy_policy")}</a>
        {t("intake.and")}
        <a onClick={() => showTermsAndConditions()}>{t("intake.terms")}</a>
    </span>
                    </label>
                </div>
            </div>
        </div>
        <div className={styles["register-button"]}>
            <div className={termsAgree ? "fill-button" : "fill-button disabled"}>
                <button id="register" data-testid={"signup-register-button"} type="submit"
                        disabled={!termsAgree}>
                    {t("intake.register")}
                </button>
            </div>
        </div>
        <div className={styles["have-a-account"]}>
            <p>
                {t("intake.already_account")} <Link to="/login">{t("intake.login")}</Link>
            </p>
        </div>
    </Form>
    );
};

interface TenderPreviewProps {
    tenderUuid: string;
    companyUuid: string | null,
    language: string;
    handleCallToRegister ?: () => void;
}

const TenderPreview: React.FC<TenderPreviewProps> = ({tenderUuid, companyUuid, language, handleCallToRegister = undefined,}) => {
    const {data, isFetching, isSuccess, isError, error} = useGetTenderPreviewQuery(
        companyUuid ? {
            tenderUuid: tenderUuid,
            companyUuid: companyUuid,
            languageIso: language,
            competitorsToFetch: 12,
        } : {
            tenderUuid: tenderUuid,
            languageIso: language,
            competitorsToFetch: 12,
    });

    if (isError) return <div>Error</div>;
    if (isFetching || data == null) return <Loader/>;
    return (
        <div className={styles["tender-preview"]}>
            <TenderDetailsPage 
                tender={data.tender}
                languageIso={language}
                previewProps={{
                    similarTenders: data.similarTenders.tenders.map(
                        tender => tender.tender
                    ),
                    handleCallToRegister: handleCallToRegister,
                }}
            />
        </div>
    );
};

interface CompetitorsOverviewProps {
    tenderUuid: string;
    companyUuid: string | null,
    language: string;
    setShowRegisterForm: (show: boolean) => void;
}
const CompetitorsOverview: React.FC<CompetitorsOverviewProps> = ({
    tenderUuid, 
    companyUuid, 
    language, 
    setShowRegisterForm,
}) => {
    const {data, isFetching, isSuccess, isError, error} = useGetTenderPreviewQuery(
        companyUuid ? {
            tenderUuid: tenderUuid,
            companyUuid: companyUuid,
            languageIso: language,
            competitorsToFetch: 12,
        } : {
            tenderUuid: tenderUuid,
            languageIso: language,
            competitorsToFetch: 12,
    });
    const [selectedCompetitorIndex,setSelectedCompetitorIndex] = useState<number|null>(0);
    const showNCompetitors = 2;

    if (isError) return <div>Error</div>;
    if (isFetching || data == null) return <Loader/>;

    return (<Competitors>
        { data.competitors.map((competitor, index) => (
            <Competitors.Item
                key={index}
                companyName={competitor.company.name}
                companyNumber={competitor.company.vatNumber}
                street = {competitor.company.street}
                postalCode = {competitor.company.postalCode}
                city = {competitor.company.city}
                country = {competitor.company.country as Country}
                sharedProjects = { selectedCompetitorIndex === index ?
                    competitor.sharedProjects :
                    null
                }
                id={(selectedCompetitorIndex === index) ? 
                    styles['selected-competitor'] :
                    ( (index >= showNCompetitors) ? 
                        styles['competitor-disabled'] : 
                        '' 
                    )
                }
                handleClick={(index < showNCompetitors) ? 
                    () => setSelectedCompetitorIndex(index) :
                    () => setShowRegisterForm(true)
                }
            >{ (selectedCompetitorIndex === index) && <Competitors.TenderList>{competitor.recentTenders.map((tender,i)=>(
                <Competitors.Tender 
                    key={i}
                    onClick={() => setShowRegisterForm(true)}
                    tender={tender}
                    language={language as Language}
                />
            ))}</Competitors.TenderList>}
            </Competitors.Item>))}
    </Competitors>);
};

interface HeaderProps {
    tenderUuid: string;
    companyUuid: string | null,
    language: string;
}
const Header: React.FC<HeaderProps> = ({
    tenderUuid, 
    companyUuid, 
    language, 
}) => {
    const {data, isFetching, isSuccess, isError, error} = useGetTenderPreviewQuery(
        companyUuid ? {
            tenderUuid: tenderUuid,
            companyUuid: companyUuid,
            languageIso: language,
            competitorsToFetch: 12,
        } : {
            tenderUuid: tenderUuid,
            languageIso: language,
            competitorsToFetch: 12,
    });

    if (isError) return <div>Error</div>;
    if (isFetching || data == null) return <Loader/>;
    const hasCompetitors = data.competitors.length > 0;

    return (<HeaderButtons>
        <WhatIsTenderwolfButton/>
        <FollowYourCompetitorsButton 
            hidden={!hasCompetitors}    
        />
    </HeaderButtons>);
}

function getBannerMessage(): string {
    return "Wil je, gratis en geheel vrijblijvend, meer overheidsopdrachten op maat ontvangen? Registreer je in minder dan 30 seconden. " +
        "Geen verborgen kosten of verplichtingen, geen creditcard, geen stilzwijgende activatie of verlenging.";
}