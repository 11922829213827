import { ResourceKey } from "i18next";

export const SearchFilterTags: ResourceKey = {
  RESET_FILTERS: "Reset filters",
  FIELD_GROUP_TITLE: "Field: Title",
  FIELD_GROUP_DESCRIPTION: "Field: Description",
  FIELD_GROUP_SPECIFICATIONS: "Field: Specifications",
  FIELD_GROUP_ADDENDA: "Field: Addenda",
  FIELD_GROUP_AWARD_COMPANY_NAMES: "Field: winners",
  FIELD_GROUP_BID_COMPANY_NAMES: "Field: participants",

  PUBLICATION_DATE_EVERYTHING: "",
  PUBLICATION_DATE_YESTERDAY: "",
  PUBLICATION_DATE_LAST_WEEK: "",
  PUBLICATION_DATE_LAST_MONTH: "",
  PUBLICATION_DATE_LAST_TWO_MONTHS: "",
  PUBLICATION_DATE_LAST_YEAR: "",
  PUBLICATION_DATE_CUSTOM: "",

  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "",

  ESTIMATED_RENEWAL_DATE_NEXT_MONTH: "",
  ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS: "",
  ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS: "",
  ESTIMATED_RENEWAL_DATE_NEXT_YEAR: "",
  ESTIMATED_RENEWAL_DATE_ALL: "",

  DEADLINE_EVERYTHING: "",
  DEADLINE_NOT_YET_EXPIRED: "",
  DEADLINE_IN_7_DAYS: "",
  DEADLINE_IN_14_DAYS: "",
  DEADLINE_IN_1_MONTH: "",
  DEADLINE_IN_2_MONTHS: "",
  DEADLINE_CUSTOM: "",
  DEADLINE_THIS_YEAR: "",
  DEADLINE_PREVIOUS_YEAR: "",
  DEADLINE_TWO_YEARS_AGO: "",
  DEADLINE_MORE_THAN_TWO_YEARS_AGO: "",
  COUNTRY_PREFIX: "Country:",
  LANGUAGE_PREFIX: "Language:",
  HAS_PRIOR_INFORMATION_NOTICE: "Publication Type: Prior information notice",
  HAS_CONTRACT_NOTICE: "Publication Type: Contract notice",
  HAS_CONTRACT_AWARD_NOTICE: "Publication Type: Contract award notice",
  HAS_DESIGN_CONTEST: "Publication Type: Award modification",
  HAS_CORRIGENDA: "Publication Type: Design contest",
  HAS_AWARD_MODIFICATIONS: "Publication Type: Corrigendum",
  HAS_OPENING_REPORT: "Publication Type: Opening report",
  HAS_MUNICIPAL_DECISION: "Publication Type: Municipal council report",
  HAS_ADDENDA: "Addenda: yes",
  HAS_AWARDS: "Awarded: yes",

  DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE: "",
  DOES_NOT_HAVE_DESIGN_CONTEST: "",
  DOES_NOT_HAVE_CORRIGENDA: "",
  DOES_NOT_HAVE_AWARD_MODIFICATIONS: "",
  DOES_NOT_HAVE_OPENING_REPORT: "",
  DOES_NOT_HAVE_ADDENDA: "Addenda: yes",
  CONTRACT_TYPE_SERVICES: "Contract type: Services",
  CONTRACT_TYPE_WORKS: "Contract type: Works",
  CONTRACT_TYPE_SUPPLIES: "Contract type: Supplies",
  CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "Contracting authority: International organisation",
  CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Contracting authority: Ministry",
  CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Contracting authority: Utilities",
  CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Contracting authority: Body governed by public law",
  CONTRACTING_AUTHORITY_TYPE_RA: "Contracting authority: Regional authority",
  CONTRACTING_AUTHORITY_TYPE_LA: "Contracting authority: Local authority",
  CONTRACTING_AUTHORITY_TYPE_CGA: "Contracting authority: Central government agency",
  CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Contracting authority: Defence contractor",
  CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Contracting authority: Public undertaking",
  CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Contracting authority: Regional agency",
  CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "Contracting authority: National agency",
  CONTRACTING_AUTHORITY_TYPE_OTHER: "Contracting authority: Other",
  PUBLICATION_RADIUS_EUROPEAN_UNION: "European publication: Yes",
  PUBLICATION_RADIUS_NATIONAL: "European publication: No",

  TENDER_UUID_FILTER_LEADS: "",
  TENDER_UUID_FILTER_FOLLOWED_TENDERS: "",
  TENDER_UUID_FILTER_OPPORTUNITIES: "",
  PROCEDURE_TYPE_OPEN: "Procedure: Open",
  PROCEDURE_TYPE_RESTRICTED: "Procedure: Restricted",
  PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Procedure: Competitive dialogue",
  PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Procedure: Innovation partnership",
  PROCEDURE_TYPE_NEGOTIATED: "Procedure: Negotiated",
  PROCEDURE_TYPE_OTHER: "Procedure: Other",

  IS_FRAMEWORK_AGREEMENT_YES: "Framework agreements",
  IS_FRAMEWORK_AGREEMENT_NO: "No framework agreements",
  IS_SHELTERED_WORKSHOP: "Reserved for sheltered workshops",
  IS_SHELTERED_PROGRAM: "Sheltered employment programs",

  until: "until",
  label: "Label",
  notYetExpired: "not yet expired",
  limited_results_message_part_one: "Your current price plan only allows you to view ",
  limited_results_message_part_two: " results. Upgrade your plan to view and search the remaining results.",
  hints_date_message_part_one: "You are now searching within the period from ",
  hints_date_message_part_two: " to ",
  hints_date_message_part_three: ". Adjust the date in the sidebar to broaden your search.",
  trial_message_part_one: "Your settings have not yet been optimized by one of our experts. ",
  trial_message_part_two: "Contact us",
  trial_message_part_three: " for help fine-tuning your settings ",
  trial_message_part_four: "or have a look at ",
  trial_message_part_five: "Getting Started With TenderWolf",
  search: "Search",
  similarTenderUuid: "Similar to"
};
