import {auto} from "framer-motion/types/render/dom/value-types/type-auto";

export const FADE_IN = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1, transition: { type: 'spring', bounce:.4, duration: .2 } }
};

export const FADE_IN_BOTTOM = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', bounce:.4 } }
};

export const FADE_IN_RIGHT = {
        hidden: { opacity: 0, x: 30},
        visible: { opacity: 1, x: 0, transition: { type: 'spring', bounce:.4} },
};

export const HOVER = {
        hidden: { y: 0 },
        visible: { y: -4, transition: { type: 'spring', bounce:.4} }
};

export const POP = {
        hidden: { scale: 1 },
        visible: { scale: 1.1, transition: { type: 'spring', bounce:.4} }
};