import { Country } from "../../../../consts/countries";

export function getCountry(language: string): Country {
    switch (language) {
        case 'nl':
            return Country.NL;
        case 'fr':
            return Country.FR;
        default:
            return Country.UK;
    }
}